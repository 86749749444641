<template>
	<div v-loading="loading" id="word1" class="wrap">
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>身份证已过期请重新上传</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible2" width="15.44rem" :before-close="handleClose">
			<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
				<div id="word" ref="word" class="print-main"></div>
				<div style="display: flex;">
					<div @click="down"
						style="width: 1.73rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.22rem;">
						文档下载</div>
					<div v-print="printOption"
						style="width: 3.07rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
						打印</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible1" width="15.44rem" :before-close="handleClose">
			<div class="camera_outer"
				style="width: 14.44rem;display: flex;justify-content: center;flex-direction: column;align-items: center;">
				<video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
				<canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
				<div @click="setImage()"
					style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
					点击拍照</div>
			</div>
		</el-dialog>
		<div class="wrap_left">
			<div style="display: flex;justify-content: space-between;align-items: center;">
				<div style="margin-top: 0.2rem;margin-left: 0.38rem;">
					<div style="display: flex;align-items: center;">
						<img style="width: 0.26rem;height: 0.26rem;" src="../assets/image/index2.png" alt=""
							srcset="" />
						<div class="my_template">{{ name }}</div>
					</div>
					<div style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-top: 0.06rem;">
						新设备首次启用，适用于车用气瓶使用登记</div>
				</div>
				<div @click="back" style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-right: 0.39rem;">《 返回
				</div>
			</div>
			<div
				style="width: 11.96rem;height: 8.8rem;background: #FFFFFF;border: 0.01rem solid #CCD9F2;margin: 0 auto;margin-top: 0.24rem;">
				<div
					style="display: flex;width: 11.96rem;height: 0.66rem;background: #F2F6FA;align-items: center;padding-left: 0.32rem;box-sizing: border-box;">
					<div style="display: flex;" v-for="(item, index) in img_list1" @click="select(index)"
						:class="current == index ? 'select1' : 'select'">
						<div v-if="index != 0">
							{{ item.title }}
						</div>
						<el-dropdown v-if="index == 0" @command="handleCommand">
							<span class="el-dropdown-link">
								{{ item.title }}第{{command_index+1}}组<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in img_list1[0].hege"
									:command="index">第{{index+1}}组</el-dropdown-item>
								<!-- <el-dropdown-item command="b">狮子头</el-dropdown-item>
						    <el-dropdown-item command="c">螺蛳粉</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>


						<!-- <el-select  v-model="value" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select> -->
					</div>
				</div>
				<div v-if="current == 0">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].hege[command_index].img[current1].imageUrl"
										alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">

									</div>
								</div>
							</div>
						</div>
						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(0)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(0)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>
						</div>
					</div>
					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;"
							v-for="(item, index) in img_list1[current].hege[command_index].img">
							<div :class="index == current1 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>

				<div v-if="current != 0">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].img[current1].imageUrl" alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">
									</div>
								</div>
							</div>
						</div>

						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(1)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(1)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>
						</div>
					</div>

					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">

						<div @click="todetails(index)" style="margin-right: 0.13rem;"
							v-for="(item, index) in img_list1[current].img">
							<div :class="index == current1 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="wrap_right">
			<div style="display: flex;justify-content: center;margin-top: 0.26rem;">
				<div @click="down"
					style="width: 1.73rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin-right: 0.22rem;">
					文档下载
				</div>
				<div @click="print"
					style="width: 3.07rem;height: 0.54rem;background: #F6990E;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
					预览打印
				</div>
			</div>
			<div
				style="width: 6.66rem;height: 8.78rem;background: #FFFFFF;border: 0.01rem solid #CCD9F2;margin: 0 auto;margin-top: 0.26rem;">
				<div style="margin-top: 0.32rem;text-align: center;font-size: 0.19rem;">
					特种设备使用登记表
				</div>
				<div style="margin: 0.21rem 0 0 1.1rem;font-size: 0.15rem;">
					登记类别：新设备首次启用
				</div>
				<div
					style="width: 5.92rem;height: 7.22rem;border: 2px #000 solid;margin: 0 auto;margin-top: 0.1rem;font-size: 0.15rem;">
					<div style="display: flex;">
						<div class="table_left">设备基本情况</div>
						<div class="table_right">
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									设备品种
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].车用气瓶" placeholder=""></el-input>
								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									产品名称
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list02 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege1" v-model="list1[0].产品名称" placeholder=""></el-input>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶数量
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list22 == 1?'background':''" style="width: 100%;">
										<el-input @blur="anzhuang2" v-model="list1[2].气瓶数量" placeholder=""></el-input>
									</div>
								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									充装介质
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list03 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege2" v-model="list1[0].充装介质" placeholder=""></el-input>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶公称工作压力
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div v-if="img_list1[0].hege.length == 1" :class="list04 == 1?'background':''"
										style="width: 100%;">
										<el-input @blur="hege3" v-model="list1[0].工作压力" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 2"
										:class="list04 == 1||list09 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege3" v-model="list1[0].工作压力 + '/' + list1[0].工作压力1"
											placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 3"
										:class="list04 == 1||list09 == 1||list005 == 1?'background':''"
										style="width: 100%;">
										<el-input @blur="hege3" v-model="list1[0].工作压力 + '/' + list1[0].工作压力1 + '/' + list1[0].工作压力2"
											placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 4"
										:class="list04 == 1||list09 == 1||list005 == 1||list010 == 1?'background':''"
										style="width: 100%;">
										<el-input @blur="hege3"
											v-model="list1[0].工作压力 + '/' + list1[0].工作压力1 + '/' + list1[0].工作压力2 + '/' + list1[0].工作压力3"
											placeholder=""></el-input>
									</div>

								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶容积
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<!-- <el-input v-model="list1[0].气瓶容积" placeholder=""></el-input> -->
									<div v-if="img_list1[0].hege.length == 1" :class="list05 == 1?'background':''"
										style="width: 100%;">
										<el-input @blur="hege4" v-model="list1[0].气瓶容积" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 2"
										:class="list05 == 1||list001 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege4" v-model="list1[0].气瓶容积 + '/' + list1[0].气瓶容积1"
											placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 3"
										:class="list05 == 1||list001 == 1||list006 == 1?'background':''"
										style="width: 100%;">

										<el-input @blur="hege4" v-model="list1[0].气瓶容积 + '/' + list1[0].气瓶容积1 + '/' + list1[0].气瓶容积2"
											placeholder=""></el-input>
									</div>
									<div v-if="img_list1[0].hege.length == 4"
										:class="list05 == 1||list001 == 1||list006 == 1||list011 == 1?'background':''"
										style="width: 100%;">
										<el-input @blur="hege4"
											v-model="list1[0].气瓶容积 + '/' + list1[0].气瓶容积1 + '/' + list1[0].气瓶容积2 + '/' + list1[0].气瓶容积3"
											placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									制造单位名称
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									制造日期
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									产品编号
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									单位内编号
								</div>
							</div>
							<!-- 四个空行 -->
							<div style="display: flex;">
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list06 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege5" v-model="list1[0].制作单位名称" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list07 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege6" v-model="list1[0].制造日期" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list08 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege7" v-model="list1[0].产品编号" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list21 == 1?'background':''" style="width: 1.38rem;height: 0.26rem;">
										<el-input v-model="list1[2].单位内编号" placeholder=""></el-input>
									</div>

								</div>
							</div>

							<div style="display: flex;">
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list002 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege8" v-model="list1[0].制作单位名称1" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list003 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege9" v-model="list1[0].制造日期1" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list004 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege10" v-model="list1[0].产品编号1" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list26 == 1?'background':''" style="width: 1.38rem;height: 0.26rem;">
										<el-input v-model="list1[2].单位内编号1" placeholder=""></el-input>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list007 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege11" v-model="list1[0].制作单位名称2" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list008 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege12" v-model="list1[0].制造日期2" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list009 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege13" v-model="list1[0].产品编号2" placeholder=""></el-input>
									</div>

								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list27 == 1?'background':''" style="width: 1.38rem;height: 0.26rem;">
										<el-input v-model="list1[2].单位内编号2" placeholder=""></el-input>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list012 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege14" v-model="list1[0].制作单位名称3" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list013 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege15" v-model="list1[0].制造日期3" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list014 == 1?'background':''" style="width: 100%;">
										<el-input @blur="hege16" v-model="list1[0].产品编号3" placeholder=""></el-input>
									</div>
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list28 == 1?'background':''" style="width: 1.38rem;height: 0.26rem;">
										<el-input v-model="list1[2].单位内编号3" placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									施工单位名称
								</div>

								<div :class="current == 2?'brightness':''"
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div :class="list23 == 1?'background':''" style="width: 100%;">
										<el-input @blur="anzhuang1" v-model="list1[2].安装单位" placeholder=""></el-input>
									</div>
								</div>


							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									监督检验机构名称
								</div>
								<div
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
							</div>

						</div>
					</div>


					<div style="display: flex;">
						<div class="table_left" style="height: 1.89rem;">设备使用情况</div>
						<div class="table_right">
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									使用单位名称
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<div :class="list11 == 1?'background':''" style="width: 100%;">
											<el-input @blur="idCard1" v-model="list1[1].姓名" placeholder=""></el-input>
										</div>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<div :class="list14 == 1?'background':''" style="width: 100%;">
											<el-input @blur="idCard2" v-model="list1[1].名称" placeholder=""></el-input>
										</div>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									使用单位地址
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<div :class="list12 == 1?'background':''" style="width: 100%;">
											<el-input @blur="idCard3" v-model="list1[1].住址" placeholder=""></el-input>
										</div>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<div :class="list15 == 1?'background':''" style="width: 100%;">
											<el-input  @blur="idCard4" v-model="list1[1].住所" placeholder=""></el-input>
										</div>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;">
									使用单位统一社会信用代码
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<div :class="list13 == 1?'background':''" style="width: 100%;">
											<el-input @blur="idCard5" v-model="list1[1].公民身份号码" placeholder=""></el-input>
										</div>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<div :class="list16 == 1?'background':''" style="width: 100%;">
											<el-input @blur="idCard6" v-model="list1[1].统一社会信用代码" placeholder=""></el-input>
										</div>
									</div>
								</div>
								<div
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									邮政编码
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 1.38rem;height: 0.44rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									<div :class="list17 == 1?'background':''" style="width: 100%;">
										<el-input v-model="list1[1].邮政编码" placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									车牌号
								</div>
								<div :class="current == 3?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<div :class="list31 == 1?'background':''" style="width: 100%;">
										<el-input @blur="registration1" v-model="list1[3].机动车登记编号" placeholder=""></el-input>
									</div>
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									车辆VIN码
								</div>
								<div :class="current == 3?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<div :class="list32 == 1?'background':''" style="width: 100%;">
										<el-input @blur="registration2" v-model="list1[3].车辆识别代号" placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									投入使用日期
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<el-date-picker :clearable="false" style="width: 1.38rem;" format="yyyy 年 MM 月 dd 日"
										value-format="yyyy-MM-dd" v-model="list1[2].投入使用日期" @change="changeTime"
										type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									单位固定电话
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">

								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									安全管理员
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<div :class="list24 == 1?'background':''" style="width: 100%;">
										<el-input @blur="anzhuang3" v-model="list1[2].安全管理员" placeholder=""></el-input>
									</div>
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									移动电话
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<div :class="list25 == 1?'background':''" style="width: 100%;">
										<el-input v-model="list1[2].移动电话" placeholder=""></el-input>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div style="width: 5.92rem;height: 1.5rem;border-bottom: 0.01rem solid #787B81;">
						<div style="text-indent: 2em;margin-top: 0.1rem;padding: 0 0.05rem;">
							在此申明：所申报的内容真实；在使用过程中，将严格执行《中华人民共和国特种设备安全法》及相关规定，并且接受特种设备安全管理部门的监督管理。</div>
						<div style="display: flex;margin-top: 0.18rem;">
							<div style="margin-left: 0.5rem;">使用单位填表人员：</div>
							<div style="margin-left: 0.28rem;">(手签)</div>
							<div style="margin-left: 0.15rem;">日期</div>
						</div>
						<div style="display: flex;justify-content: right;margin-top: 0.24rem;">(使用单位公章)</div>
						<div style="display: flex;width: 100%;">
							<div style="display: flex;">
								<div style="margin-left: 0.25rem;">使用单位安全管理人员：</div>
								<div style="margin-left: 0.28rem;">(手签)</div>
								<div style="margin-left: 0.15rem;">日期</div>
							</div>
							<div style="margin-left: 1.92rem;"><span>年</span><span
									style="margin-left: 0.23rem;">月</span><span style="margin-left: 0.23rem;">日</span>
							</div>
						</div>
					</div>
					<div style="width: 5.92rem;height: 1.07rem;">
						<div>说明：</div>
						<div style="display: flex;">
							<div style="margin-left: 0.5rem;">登记机关登记人员：</div>
							<div style="margin-left: 0.15rem;">日期</div>
						</div>
						<div style="display: flex;justify-content: right;margin-top: 0.15rem;">(使用单位公章)</div>
						<div style="display: flex;width: 100%;">
							<div style="display: flex;">
								<div style="margin-left: 0.5rem;">使用登记证编号：</div>
							</div>
							<div style="margin-left: 3.25rem;"><span>年</span><span
									style="margin-left: 0.23rem;">月</span><span style="margin-left: 0.23rem;">日</span>
							</div>
						</div>
					</div>

				</div>
				<div style="margin-left: 0.35rem;margin-top: 0.1rem;">注：本式样适用于车用气瓶使用登记。</div>
			</div>
		</div>
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	//import printJS from 'print-js'
	import BlobUtil from 'blob-util';
	export default {
		// components: {
		// 	VZoomBox
		// },
		data() {
			return {
				saveList: [],
				list01: 0,
				list02: 0,
				list03: 0,
				list04: 0,
				list05: 0,
				list06: 0,
				list07: 0,
				list08: 0,
				list09: 0,
				list001: 0,
				list002: 0,
				list003: 0,
				list004: 0,
				list005: 0,
				list006: 0,
				list007: 0,
				list008: 0,
				list009: 0,
				list010: 0,
				list011: 0,
				list012: 0,
				list013: 0,
				list014: 0,
				list11: 0,
				list12: 0,
				list13: 0,
				list14: 0,
				list15: 0,
				list16: 0,
				list17: 0,
				list21: 0,
				list22: 0,
				list23: 0,
				list24: 0,
				list25: 0,
				list26: 0,
				list27: 0,
				list28: 0,
				list31: 0,
				list32: 0,
				command_index: 0,
				current3: 0,
				isexpire: false,
				saomiao_show: false,
				rotation: 0,
				imgWidth: "6.96rem",
				imgHeight: "auto",
				scale: 1,
				translateX: 0,
				translateY: 0,
				loading: false,
				dialogVisible2: false,
				printOption: {
					id: 'word', // 打印元素的id 不需要携带#号
					//popTitle: '工作任务' // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
				},
				current2: 0,
				list1: this.$route.query.list1,
				list2: this.$route.query.list1,
				list: [{
					list: ["产品名称", "充装介质", "工作压力", "气瓶容积", "制作单位名称", "制造日期", "产品编号"],
				}, {
					list: ["个人或营业执照名称", "身份证地址或营业执照住所", "身份证号或使用单位统一社会信用代码"],
				}, {
					list: ["气瓶数量", "安装单位", "安全管理员"],
				}, {
					list: ["机动车登记编号", "车辆识别代号"],
				}, {
					list: ["监督检验机构名称"],
				}],

				show1: false,
				videoWidth: 960,
				videoHeight: 600,
				imgSrc: "",
				thisCancas: null,
				thisContext: null,
				thisVideo: null,
				openVideo: false,
				dialogVisible1: false,
				dialogVisible: false,
				camera: false,

				input: '',
				show: false,
				screenHeight: '',
				current: 0,
				current1: 0,
				img_list1: JSON.parse(this.$route.query.img_list1),
				name: this.$route.query.name,
			}
		},
		created() {
			//console.log(this.$route.query.img_list)
			console.log(JSON.parse(this.$route.query.img_list1))
			//console.log(this.$route.query.name)
			console.log(this.$route.query.list1)
			//this.$route.query.img_list1.push()
		},
		mounted() {
			this.screenHeight = document.body.clientHeight
			window.onresize = () => {
				return (() => {
					this.screenHeight = document.body.clientHeight
				})()
			}
			this.dis_certificate_api()
			//this.loading = true
			this.saomiao_show = true
		},
		watch: {
			screenHeight: {
				handler: function(val, oldVal) {
					if (val < 1080) {
						console.log('屏幕宽度小于1080px')
						this.show = false
					} else {
						console.log('屏幕宽度大于1080px')

						if (this.current == 0 || this.current == 1) {
							this.show = false
						} else {
							this.show = true
						}
					}
				}
				//immediate: true
			},
		},
		computed: {

		},
		methods: {
			hege1(e){
				var _this = this
				console.log(e)
				if (this.list2[0].产品名称 == this.list1[0].产品名称) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[0].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[0].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '产品名称')
					formData.append('after', this.list1[0].产品名称)
					formData.append('before', this.list2[0].产品名称)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege2(e){
				var _this = this
				console.log(e)
				if (this.list2[0].充装介质 == this.list1[0].充装介质) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[0].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[0].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '充装介质')
					formData.append('after', this.list1[0].充装介质)
					formData.append('before', this.list2[0].充装介质)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege3(e){
				
			},
			hege4(e){
				
			},
			hege5(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制作单位名称 == this.list1[0].制作单位名称) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[0].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[0].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制作单位名称')
					formData.append('after', this.list1[0].制作单位名称)
					formData.append('before', this.list2[0].制作单位名称)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege6(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制造日期 == this.list1[0].制造日期) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[0].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[0].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制造日期')
					formData.append('after', this.list1[0].制造日期)
					formData.append('before', this.list2[0].制造日期)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege7(e){
				var _this = this
				console.log(e)
				if (this.list2[0].产品编号 == this.list1[0].产品编号) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[0].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[0].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '产品编号')
					formData.append('after', this.list1[0].产品编号)
					formData.append('before', this.list2[0].产品编号)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege8(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制作单位名称1 == this.list1[0].制作单位名称1) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制作单位名称1')
					formData.append('after', this.list1[0].制作单位名称1)
					formData.append('before', this.list2[0].制作单位名称1)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege9(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制造日期1 == this.list1[0].制造日期1) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制造日期1')
					formData.append('after', this.list1[0].制造日期1)
					formData.append('before', this.list2[0].制造日期1)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege10(e){
				var _this = this
				console.log(e)
				if (this.list2[0].产品编号1 == this.list1[0].产品编号1) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '产品编号1')
					formData.append('after', this.list1[0].产品编号1)
					formData.append('before', this.list2[0].产品编号1)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege11(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制作单位名称2 == this.list1[0].制作单位名称2) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制作单位名称2')
					formData.append('after', this.list1[0].制作单位名称2)
					formData.append('before', this.list2[0].制作单位名称2)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege12(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制造日期2 == this.list1[0].制造日期2) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制造日期2')
					formData.append('after', this.list1[0].制造日期2)
					formData.append('before', this.list2[0].制造日期2)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege13(e){
				var _this = this
				console.log(e)
				if (this.list2[0].产品编号2 == this.list1[0].产品编号2) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '产品编号2')
					formData.append('after', this.list1[0].产品编号2)
					formData.append('before', this.list2[0].产品编号2)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege14(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制作单位名称3 == this.list1[0].制作单位名称3) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[3].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[3].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制作单位名称3')
					formData.append('after', this.list1[0].制作单位名称3)
					formData.append('before', this.list2[0].制作单位名称3)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege15(e){
				var _this = this
				console.log(e)
				if (this.list2[0].制造日期3 == this.list1[0].制造日期3) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[3].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[3].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '制造日期3')
					formData.append('after', this.list1[0].制造日期3)
					formData.append('before', this.list2[0].制造日期3)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			hege16(e){
				var _this = this
				console.log(e)
				if (this.list2[0].产品编号3 == this.list1[0].产品编号3) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[0].hege[3].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[0].hege[3].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '产品编号3')
					formData.append('after', this.list1[0].产品编号3)
					formData.append('before', this.list2[0].产品编号3)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard1(){
				var _this = this
				if (this.list2[1].姓名 == this.list1[1].姓名) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '姓名')
					formData.append('after', this.list1[1].姓名)
					formData.append('before', this.list2[1].姓名)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard2(){
				var _this = this
				if (this.list2[1].名称 == this.list1[1].名称) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '名称')
					formData.append('after', this.list1[1].名称)
					formData.append('before', this.list2[1].名称)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard3(){
				var _this = this
				if (this.list2[1].住址 == this.list1[1].住址) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '住址')
					formData.append('after', this.list1[1].住址)
					formData.append('before', this.list2[1].住址)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard4(){
				var _this = this
				if (this.list2[1].住所 == this.list1[1].住所) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '住所')
					formData.append('after', this.list1[1].住所)
					formData.append('before', this.list2[1].住所)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard5(){
				var _this = this
				if (this.list2[1].公民身份号码 == this.list1[1].公民身份号码) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '公民身份号码')
					formData.append('after', this.list1[1].公民身份号码)
					formData.append('before', this.list2[1].公民身份号码)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			idCard6(){
				var _this = this
				if (this.list2[1].统一社会信用代码 == this.list1[1].统一社会信用代码) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '统一社会信用代码')
					formData.append('after', this.list1[1].统一社会信用代码)
					formData.append('before', this.list2[1].统一社会信用代码)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			anzhuang1(e) {
				var _this = this
				console.log(e)
				console.log(this.list1[2])
				console.log(this.list2[2])
				if (this.list2[2].安装单位 == this.list1[2].安装单位) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '安装单位')
					formData.append('after', this.list1[2].安装单位)
					formData.append('before', this.list2[2].安装单位)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			anzhuang2(e) {
				var _this = this
				if (this.list2[2].气瓶数量 == this.list1[2].气瓶数量) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '气瓶数量')
					formData.append('after', this.list1[2].气瓶数量)
					formData.append('before', this.list2[2].气瓶数量)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			anzhuang3(e) {
				var _this = this
				if (this.list2[2].安全管理员 == this.list1[2].安全管理员) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[2].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[2].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '安全管理员')
					formData.append('after', this.list1[2].安全管理员)
					formData.append('before', this.list2[2].安全管理员)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			registration1(e){
				var _this = this
				if (this.list2[3].机动车登记编号 == this.list1[3].机动车登记编号) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[3].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[3].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '机动车登记编号')
					formData.append('after', this.list1[3].机动车登记编号)
					formData.append('before', this.list2[3].机动车登记编号)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			registration2(e){
				var _this = this
				if (this.list2[3].车辆识别代号 == this.list1[3].车辆识别代号) {
					console.log('没有修改')
				} else {
					let formData = new FormData()
					for (var i = 0; i <= this.img_list1[3].img.length - 1; i++) {
						var file = _this.dataURLtoFile(this.img_list1[3].img[i].imagePath, 143)
						console.log(URL.createObjectURL(file))
						formData.append('img', file)
					}
					formData.append('field', '车辆识别代号')
					formData.append('after', this.list1[3].车辆识别代号)
					formData.append('before', this.list2[3].车辆识别代号)
					_this.$axios.post('upload_records_api', formData).then(function(res) {
						console.log(res)
					})
				}
			},
			handleCommand(command) {
				//this.$message('click on item ' + command);
				this.command_index = command
			},
			rendered() {
				console.log('Word文档渲染完成');
			},
			back() {
				this.$router.push('/identify')
			},
			rotateImage() {
				console.log(123)
				this.rotation += 90;
				this.$forceUpdate();
			},
			big() {
				const img = document.getElementById("oImg");
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				console.log(111)
				this.imgWidth = `${this.imgWidth + 20}px`;
				this.imgHeight = `${this.imgHeight + 20}px`;
			},
			small() {
				const img = document.getElementById("oImg");
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				console.log(222)
				this.imgWidth = `${this.imgWidth - 20}px`;
				this.imgHeight = `${this.imgHeight - 20}px`;
			},
			restoration() {
				this.imgWidth = "6.96rem"
				this.imgHeight = "auto"
				this.translateX = 0
				this.translateY = 0
				this.rotation = 0
			},
			handerPictu(e) {
				console.log(123)
				const img = document.getElementById("oImg");
				console.log(img.offsetWidth, img.width, img.clientWidth);
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				if (e.deltaY > 0) {
					console.log("鼠标向下滚动，图片缩小");
					this.imgWidth = `${this.imgWidth - 20}px`;
					this.imgHeight = `${this.imgHeight - 20}px`;
				} else {
					console.log("鼠标向上滚动，图片放大");
					this.imgWidth = `${this.imgWidth + 20}px`;
					this.imgHeight = `${this.imgHeight + 20}px`;
				}
				//   this.imgWidth = `${this.imgWidth}px`;
				console.log(this.imgWidth, this.imgHeight, "hou");
			},
			// handleMousewheel(event) {
			// 	this.scale = this.scale + event.wheelDelta / 1200
			// 	return false
			// },
			handleMouseDown(event) {

				event.preventDefault()
				if (event.target.tagName !== 'IMG') {
					return false
				}
				let div = this.$refs.previewImgDiv
				let originX = event.screenX
				let originY = event.screenY
				let translateX = this.translateX
				let translateY = this.translateY
				const move = (e) => {
					let afterX = e.screenX
					let afterY = e.screenY
					this.translateX = translateX + (afterX - originX) / this.scale
					this.translateY = translateY + (afterY - originY) / this.scale
				}
				div.addEventListener('mousemove', move)
				div.addEventListener('mouseup', () => {
					div.removeEventListener('mousemove', move)
				})
			},
			changeTime() {
				console.log(this.list1[2].投入使用日期)
			},
			print() {
				console.log(222)
				if (this.isexpire) {
					this.$message({
						message: '身份证已过期请重新上传',
						type: 'error'
					});
				} else {
					if (this.img_list1[0].hege.length == 1) {
						var gongzuo = this.list1[0].工作压力
						var qiping = this.list1[0].气瓶容积
					} else if (this.img_list1[0].hege.length == 2) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1
					} else if (this.img_list1[0].hege.length == 3) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1 + '/' + this.list1[0].工作压力2
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1 + '/' + this.list1[0].气瓶容积2
					} else if (this.img_list1[0].hege.length == 4) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1 + '/' + this.list1[0].工作压力2 + '/' +
							this.list1[0].工作压力3
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1 + '/' + this.list1[0].气瓶容积2 + '/' +
							this.list1[0].气瓶容积3
					}
					
					const docx = require("docx-preview");
					var _this = this
					this.dialogVisible2 = true

					if (this.img_list1[1].title == '身份证明') {
						if (this.list1[0].车用气瓶 == '' && this.list1[0].产品名称 == '' && this.list1[0].充装介质 == '' &&
							this.list1[0].工作压力 == '' && this.list1[0].气瓶容积 == '' && this.list1[0].制作单位名称 == '' &&
							this.list1[0].制造日期 == '' && this.list1[0].产品编号 == '' && this.list1[2].单位内编号 == '' &&
							this.list1[2].气瓶数量 == '' && this.list1[2].安装单位 == '' && this.list1[2].安全管理员 == '' &&
							this.list1[1].姓名 == '' && this.list1[1].住址 == '' && this.list1[1].公民身份号码 == '' && this.list1[2]
							.移动电话 == '' &&
							this.list1[1].邮政编码 == '' && this.list1[3].机动车登记编号 == '' && this.list1[3].车辆识别代号 == '') {
							console.log('没有全部传入')
							if (this.list1[0].车用气瓶 == '') {
								this.list01 = 1
							}
							if (this.list1[0].产品名称 == '') {
								this.list02 = 1
							}
							if (this.list1[0].充装介质 == '') {
								this.list03 = 1
							}
							if (this.list1[0].工作压力 == '') {
								this.list04 = 1
							}
							if (this.list1[0].气瓶容积 == '') {
								this.list05 = 1
							}
							if (this.list1[0].制作单位名称 == '') {
								this.list06 = 1
							}
							if (this.list1[0].制造日期 == '') {
								this.list07 = 1
							}
							if (this.list1[0].产品编号 == '') {
								this.list08 = 1
							}
							if (this.list1[1].姓名 == '') {
								this.list11 = 1
							}
							if (this.list1[1].住址 == '') {
								this.list12 = 1
							}
							if (this.list1[1].公民身份号码 == '') {
								this.list13 = 1
							}
							if (this.list1[1].邮政编码 == '') {
								this.list17 = 1
							}
							if (this.list1[2].单位内编号 == '') {
								_this.list21 = 1
							}
							if (this.list1[2].气瓶数量 == '') {
								this.list22 = 1
							}
							if (this.list1[2].安装单位 == '') {
								this.list23 = 1
							}
							if (this.list1[2].安全管理员 == '') {
								this.list24 = 1
							}
							if (this.list1[2].移动电话 == '') {
								this.list25 = 1
							}
							if (this.list1[3].机动车登记编号 == '') {
								this.list31 = 1
							}
							if (this.list1[3].车辆识别代号 == '') {
								this.list32 = 1
							}
							
							if (this.img_list1[0].hege.length == 2) {
								console.log(222)
								console.log(this.list1[2].单位内编号1)
								if (this.list1[2].单位内编号1 == '') {
									console.log(111)
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							}
							if (this.img_list1[0].hege.length == 3) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							
								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							}
							
							if (this.img_list1[0].hege.length == 4) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[2].单位内编号3 == '') {
									this.list28 = 1
								} else {
									this.list28 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							
								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							
								if (this.list1[0].工作压力3 == '') {
									this.list010 = 1
								} else {
									this.list010 = 0
								}
								if (this.list1[0].气瓶容积3 == '') {
									this.list011 = 1
								} else {
									this.list011 = 0
								}
								if (this.list1[0].制作单位名称3 == '') {
									this.list012 = 1
								} else {
									this.list012 = 0
								}
								if (this.list1[0].制造日期3 == '') {
									this.list013 = 1
								} else {
									this.list013 = 0
								}
								if (this.list1[0].产品编号3 == '') {
									this.list014 = 1
								} else {
									this.list014 = 0
								}
							}
						} else {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': gongzuo,
								'气瓶容积': qiping,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[2].单位内编号,
								'气瓶数量': this.list1[2].气瓶数量,
								'安装单位': this.list1[2].安装单位,
								'安全管理员': this.list1[2].安全管理员,
								'投入使用日期': this.list1[2].投入使用日期,
								'移动电话': this.list1[2].移动电话,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[3].机动车登记编号,
								'车辆识别代号': this.list1[3].车辆识别代号,
							}
							_this.$axios.post('daochu_word_api', params, {
								responseType: 'blob',
							}).then(function(res) {
								const blob = new Blob([res.data]);
								//加载完成后，调打印预览接口
								docx.renderAsync(blob, _this.$refs.word, null, {
									className: "docx", // 默认和文档样式类的类名/前缀
									inWrapper: false, // 启用围绕文档内容渲染包装器
									ignoreWidth: false, // 禁止页面渲染宽度
									ignoreHeight: false, // 禁止页面渲染高度
									ignoreFonts: false, // 禁止字体渲染
									breakPages: false, // 在分页符上启用分页
									ignoreLastRenderedPageBreak: true, //禁用lastRenderedPageBreak元素的分页
									experimental: true, //启用实验性功能（制表符停止计算）
									trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
									debug: false, // 启用额外的日志记录
								})
							})
						}
					} else if (this.img_list1[1].title == '营业执照') {
						if (this.list1[0].车用气瓶 == '' && this.list1[0].产品名称 == '' && this.list1[0].充装介质 == '' &&
							this.list1[0].工作压力 == '' && this.list1[0].气瓶容积 == '' && this.list1[0].制作单位名称 == '' &&
							this.list1[0].制造日期 == '' && this.list1[0].产品编号 == '' && this.list1[2].单位内编号 == '' &&
							this.list1[2].气瓶数量 == '' && this.list1[2].安装单位 == '' && this.list1[2].安全管理员 == '' &&
							this.list1[1].名称 == '' && this.list1[1].住所 == '' && this.list1[1].统一社会信用代码 == '' && this.list1[
								2].移动电话 == '' &&
							this.list1[1].邮政编码 == '' && this.list1[3].机动车登记编号 == '' && this.list1[3].车辆识别代号 == '') {
							console.log('没有全部传入')
							if (this.list1[0].车用气瓶 == '') {
								this.list01 = 1
							}
							if (this.list1[0].产品名称 == '') {
								this.list02 = 1
							}
							if (this.list1[0].充装介质 == '') {
								this.list03 = 1
							}
							if (this.list1[0].工作压力 == '') {
								this.list04 = 1
							}
							if (this.list1[0].气瓶容积 == '') {
								this.list05 = 1
							}
							if (this.list1[0].制作单位名称 == '') {
								this.list06 = 1
							}
							if (this.list1[0].制造日期 == '') {
								this.list07 = 1
							}
							if (this.list1[0].产品编号 == '') {
								this.list08 = 1
							}
							if (this.list1[1].名称 == '') {
								this.list14 = 1
							}
							if (this.list1[1].住所 == '') {
								this.list15 = 1
							}
							if (this.list1[1].统一社会信用代码 == '') {
								this.list16 = 1
							}
							if (this.list1[1].邮政编码 == '') {
								this.list17 = 1
							}
							if (this.list1[2].单位内编号 == '') {
								_this.list21 = 1
							}
							if (this.list1[2].气瓶数量 == '') {
								this.list22 = 1
							}
							if (this.list1[2].安装单位 == '') {
								this.list23 = 1
							}
							if (this.list1[2].安全管理员 == '') {
								this.list24 = 1
							}
							if (this.list1[2].移动电话 == '') {
								this.list25 = 1
							}
							if (this.list1[3].机动车登记编号 == '') {
								this.list31 = 1
							}
							if (this.list1[3].车辆识别代号 == '') {
								this.list32 = 1
							}
							
							if (this.img_list1[0].hege.length == 2) {
								console.log(222)
								console.log(this.list1[2].单位内编号1)
								if (this.list1[2].单位内编号1 == '') {
									console.log(111)
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							}
							if (this.img_list1[0].hege.length == 3) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							
								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							}
							
							if (this.img_list1[0].hege.length == 4) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[2].单位内编号3 == '') {
									this.list28 = 1
								} else {
									this.list28 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							
								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							
								if (this.list1[0].工作压力3 == '') {
									this.list010 = 1
								} else {
									this.list010 = 0
								}
								if (this.list1[0].气瓶容积3 == '') {
									this.list011 = 1
								} else {
									this.list011 = 0
								}
								if (this.list1[0].制作单位名称3 == '') {
									this.list012 = 1
								} else {
									this.list012 = 0
								}
								if (this.list1[0].制造日期3 == '') {
									this.list013 = 1
								} else {
									this.list013 = 0
								}
								if (this.list1[0].产品编号3 == '') {
									this.list014 = 1
								} else {
									this.list014 = 0
								}
							}
						} else {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': gongzuo,
								'气瓶容积': qiping,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[2].气瓶数量,
								'安装单位': this.list1[2].安装单位,
								'安全管理员': this.list1[2].安全管理员,
								'投入使用日期': this.list1[2].投入使用日期,
								'移动电话': this.list1[2].移动电话,

								'名称': this.list1[1].名称,
								'住址': this.list1[1].住址,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[3].机动车登记编号,
								'车辆识别代号': this.list1[3].车辆识别代号,
							}
							_this.$axios.post('daochu_word_api', params, {
								responseType: 'blob',
							}).then(function(res) {
								const blob = new Blob([res.data]);
								//加载完成后，调打印预览接口
								docx.renderAsync(blob, _this.$refs.word, null, {
									className: "docx", // 默认和文档样式类的类名/前缀
									inWrapper: false, // 启用围绕文档内容渲染包装器
									ignoreWidth: false, // 禁止页面渲染宽度
									ignoreHeight: false, // 禁止页面渲染高度
									ignoreFonts: false, // 禁止字体渲染
									breakPages: false, // 在分页符上启用分页
									ignoreLastRenderedPageBreak: true, //禁用lastRenderedPageBreak元素的分页
									experimental: true, //启用实验性功能（制表符停止计算）
									trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
									debug: false, // 启用额外的日志记录
								})
							})
						}
					}
				}
			},
			down() {
				var _this = this
				if (this.isexpire) {
					this.$message({
						message: '身份证已过期请重新上传',
						type: 'error'
					});
				} else {
					if (this.img_list1[0].hege.length == 1) {
						var gongzuo = this.list1[0].工作压力
						var qiping = this.list1[0].气瓶容积
					} else if (this.img_list1[0].hege.length == 2) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1
					} else if (this.img_list1[0].hege.length == 3) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1 + '/' + this.list1[0].工作压力2
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1 + '/' + this.list1[0].气瓶容积2
					} else if (this.img_list1[0].hege.length == 4) {
						var gongzuo = this.list1[0].工作压力 + '/' + this.list1[0].工作压力1 + '/' + this.list1[0].工作压力2 + '/' +
							this.list1[0].工作压力3
						var qiping = this.list1[0].气瓶容积 + '/' + this.list1[0].气瓶容积1 + '/' + this.list1[0].气瓶容积2 + '/' +
							this.list1[0].气瓶容积3
					}
					if (this.img_list1[1].title == '身份证明') {
						if (this.list1[0].车用气瓶 == '' || this.list1[0].产品名称 == '' || this.list1[0].充装介质 == '' ||
							this.list1[0].工作压力 == '' || this.list1[0].气瓶容积 == '' || this.list1[0].制作单位名称 == '' ||
							this.list1[0].制造日期 == '' || this.list1[0].产品编号 == '' || this.list1[2].单位内编号 == '' ||
							this.list1[2].气瓶数量 == '' || this.list1[2].安装单位 == '' || this.list1[2].安全管理员 == '' ||
							this.list1[1].姓名 == '' || this.list1[1].住址 == '' || this.list1[1].公民身份号码 == '' || this.list1[2]
							.移动电话 == '' ||
							this.list1[1].邮政编码 == '' || this.list1[3].机动车登记编号 == '' || this.list1[3].车辆识别代号 == '') {
							console.log('没有全部传入123')
							if (this.list1[0].车用气瓶 == '') {
								this.list01 = 1
							} else {
								this.list01 = 0
							}
							if (this.list1[0].产品名称 == '') {
								this.list02 = 1
							} else {
								this.list02 = 0
							}
							if (this.list1[0].充装介质 == '') {
								this.list03 = 1
							} else {
								this.list03 = 0
							}
							if (this.list1[0].工作压力 == '') {
								this.list04 = 1
							} else {
								this.list04 = 0
							}
							if (this.list1[0].气瓶容积 == '') {
								this.list05 = 1
							} else {
								this.list05 = 0
							}
							if (this.list1[0].制作单位名称 == '') {
								this.list06 = 1
							} else {
								this.list06 = 0
							}
							if (this.list1[0].制造日期 == '') {
								this.list07 = 1
							} else {
								this.list07 = 0
							}
							if (this.list1[0].产品编号 == '') {
								this.list08 = 1
							} else {
								this.list08 = 0
							}
							if (this.list1[1].姓名 == '') {
								this.list11 = 1
							} else {
								this.list11 = 0
							}
							if (this.list1[1].住址 == '') {
								this.list12 = 1
							} else {
								this.list12 = 0
							}
							if (this.list1[1].公民身份号码 == '') {
								this.list13 = 1
							} else {
								this.list13 = 0
							}
							if (this.list1[1].邮政编码 == '') {
								this.list17 = 1
							} else {
								this.list17 = 0
							}
							if (this.list1[2].单位内编号 == '') {
								_this.list21 = 1
							} else {
								this.list21 = 0
							}
							if (this.list1[2].气瓶数量 == '') {
								this.list22 = 1
							} else {
								this.list22 = 0
							}
							if (this.list1[2].安装单位 == '') {
								this.list23 = 1
							} else {
								this.list23 = 0
							}
							if (this.list1[2].安全管理员 == '') {
								this.list24 = 1
							} else {
								this.list24 = 0
							}
							if (this.list1[2].移动电话 == '') {
								this.list25 = 1
							} else {
								this.list25 = 0
							}
							if (this.list1[3].机动车登记编号 == '') {
								this.list31 = 1
							} else {
								this.list31 = 0
							}
							if (this.list1[3].车辆识别代号 == '') {
								this.list32 = 1
							} else {
								this.list32 = 0
							}
							console.log(this.img_list1[0].hege.length)
							if (this.img_list1[0].hege.length == 2) {
								console.log(222)
								console.log(this.list1[2].单位内编号1)
								if (this.list1[2].单位内编号1 == '') {
									console.log(111)
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							}
							if (this.img_list1[0].hege.length == 3) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}

								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							}

							if (this.img_list1[0].hege.length == 4) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[2].单位内编号3 == '') {
									this.list28 = 1
								} else {
									this.list28 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}

								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}

								if (this.list1[0].工作压力3 == '') {
									this.list010 = 1
								} else {
									this.list010 = 0
								}
								if (this.list1[0].气瓶容积3 == '') {
									this.list011 = 1
								} else {
									this.list011 = 0
								}
								if (this.list1[0].制作单位名称3 == '') {
									this.list012 = 1
								} else {
									this.list012 = 0
								}
								if (this.list1[0].制造日期3 == '') {
									this.list013 = 1
								} else {
									this.list013 = 0
								}
								if (this.list1[0].产品编号3 == '') {
									this.list014 = 1
								} else {
									this.list014 = 0
								}
							}


						} else {
							console.log(this.list1[2].单位内编号)
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': gongzuo,
								'气瓶容积': qiping,
								'制作单位名称': this.list1[0].制作单位名称,
								'制作单位名称1': this.list1[0].制作单位名称1,
								'制作单位名称2': this.list1[0].制作单位名称2,
								'制作单位名称3': this.list1[0].制作单位名称3,
								'制造日期': this.list1[0].制造日期,
								'制造日期1': this.list1[0].制造日期1,
								'制造日期2': this.list1[0].制造日期2,
								'制造日期3': this.list1[0].制造日期3,
								'产品编号': this.list1[0].产品编号,
								'产品编号1': this.list1[0].产品编号1,
								'产品编号2': this.list1[0].产品编号2,
								'产品编号3': this.list1[0].产品编号3,
								'单位内编号': this.list1[2].单位内编号,
								'单位内编号1': this.list1[2].单位内编号1,
								'单位内编号2': this.list1[2].单位内编号2,
								'单位内编号3': this.list1[2].单位内编号3,
								'气瓶数量': this.list1[2].气瓶数量,
								'安装单位': this.list1[2].安装单位,
								'安全管理员': this.list1[2].安全管理员,
								'移动电话': this.list1[2].移动电话,
								'投入使用日期': this.list1[2].投入使用日期,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[3].机动车登记编号,
								'车辆识别代号': this.list1[3].车辆识别代号,
							}
							_this.$axios.post('daochu_word_api', params, {
								responseType: 'blob',
							}).then(function(res) {
								console.log(res)
								const blob = new Blob([res.data]);
								let downloads = document.createElement("a");
								let href = window.URL.createObjectURL(blob);
								downloads.href = href;
								downloads.download = 'cccc.docx';
								document.body.appendChild(downloads);
								downloads.click();
								document.body.removeChild(downloads);
								window.URL.revokeObjectURL(href);
							})
						}

					} else if (this.img_list1[1].title == '营业执照') {
						if (this.list1[0].车用气瓶 == '' && this.list1[0].产品名称 == '' && this.list1[0].充装介质 == '' &&
							this.list1[0].工作压力 == '' && this.list1[0].气瓶容积 == '' && this.list1[0].制作单位名称 == '' &&
							this.list1[0].制造日期 == '' && this.list1[0].产品编号 == '' && this.list1[2].单位内编号 == '' &&
							this.list1[2].气瓶数量 == '' && this.list1[2].安装单位 == '' && this.list1[2].安全管理员 == '' &&
							this.list1[1].名称 == '' && this.list1[1].住所 == '' && this.list1[1].统一社会信用代码 == '' && this.list1[
								2].移动电话 == '' &&
							this.list1[1].邮政编码 == '' && this.list1[3].机动车登记编号 == '' && this.list1[3].车辆识别代号 == '') {
							console.log('没有全部传入123')
							if (this.list1[0].车用气瓶 == '') {
								this.list01 == 1
							} else {
								this.list01 = 0
							}
							if (this.list1[0].产品名称 == '') {
								this.list02 == 1
							} else {
								this.list02 = 0
							}
							if (this.list1[0].充装介质 == '') {
								this.list03 == 1
							} else {
								this.list03 = 0
							}
							if (this.list1[0].工作压力 == '') {
								this.list04 == 1
							} else {
								this.list04 = 0
							}
							if (this.list1[0].气瓶容积 == '') {
								this.list05 == 1
							} else {
								this.list05 = 0
							}
							if (this.list1[0].制作单位名称 == '') {
								this.list06 == 1
							} else {
								this.list06 = 0
							}
							if (this.list1[0].制造日期 == '') {
								this.list07 == 1
							} else {
								this.list07 = 0
							}
							if (this.list1[0].产品编号 == '') {
								this.list08 == 1
							} else {
								this.list08 = 0
							}
							if (this.list1[1].名称 == '') {
								this.list14 == 1
							} else {
								this.list14 = 0
							}
							if (this.list1[1].住所 == '') {
								this.list15 == 1
							} else {
								this.list15 = 0
							}
							if (this.list1[1].统一社会信用代码 == '') {
								this.list16 == 1
							} else {
								this.list16 = 0
							}
							if (this.list1[1].邮政编码 == '') {
								this.list17 == 1
							} else {
								this.list17 = 0
							}
							if (this.list1[2].单位内编号 == '') {
								_this.list21 == 1
							} else {
								this.list21 = 0
							}
							if (this.list1[2].气瓶数量 == '') {
								this.list22 == 1
							} else {
								this.list22 = 0
							}
							if (this.list1[2].安装单位 == '') {
								this.list23 == 1
							} else {
								this.list23 = 0
							}
							if (this.list1[2].安全管理员 == '') {
								this.list24 == 1
							} else {
								this.list24 = 0
							}
							if (this.list1[2].移动电话 == '') {
								this.list25 == 1
							} else {
								this.list25 = 0
							}
							if (this.list1[3].机动车登记编号 == '') {
								this.list31 == 1
							} else {
								this.list31 = 0
							}
							if (this.list1[3].车辆识别代号 == '') {
								this.list32 == 1
							} else {
								this.list32 = 0
							}

							if (this.img_list1[0].hege.length == 2) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}
							}
							if (this.img_list1[0].hege.length == 3) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}

								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}
							}

							if (this.img_list1[0].hege.length == 4) {
								if (this.list1[2].单位内编号1 == '') {
									this.list26 = 1
								} else {
									this.list26 = 0
								}
								if (this.list1[2].单位内编号2 == '') {
									this.list27 = 1
								} else {
									this.list27 = 0
								}
								if (this.list1[2].单位内编号3 == '') {
									this.list28 = 1
								} else {
									this.list28 = 0
								}
								if (this.list1[0].工作压力1 == '') {
									this.list09 = 1
								} else {
									this.list09 = 0
								}
								if (this.list1[0].气瓶容积1 == '') {
									this.list001 = 1
								} else {
									this.list001 = 0
								}
								if (this.list1[0].制作单位名称1 == '') {
									this.list002 = 1
								} else {
									this.list002 = 0
								}
								if (this.list1[0].制造日期1 == '') {
									this.list003 = 1
								} else {
									this.list003 = 0
								}
								if (this.list1[0].产品编号1 == '') {
									this.list004 = 1
								} else {
									this.list004 = 0
								}

								if (this.list1[0].工作压力2 == '') {
									this.list005 = 1
								} else {
									this.list005 = 0
								}
								if (this.list1[0].气瓶容积2 == '') {
									this.list006 = 1
								} else {
									this.list006 = 0
								}
								if (this.list1[0].制作单位名称2 == '') {
									this.list007 = 1
								} else {
									this.list007 = 0
								}
								if (this.list1[0].制造日期2 == '') {
									this.list008 = 1
								} else {
									this.list008 = 0
								}
								if (this.list1[0].产品编号2 == '') {
									this.list009 = 1
								} else {
									this.list009 = 0
								}

								if (this.list1[0].工作压力3 == '') {
									this.list010 = 1
								} else {
									this.list010 = 0
								}
								if (this.list1[0].气瓶容积3 == '') {
									this.list011 = 1
								} else {
									this.list011 = 0
								}
								if (this.list1[0].制作单位名称3 == '') {
									this.list012 = 1
								} else {
									this.list012 = 0
								}
								if (this.list1[0].制造日期3 == '') {
									this.list013 = 1
								} else {
									this.list013 = 0
								}
								if (this.list1[0].产品编号3 == '') {
									this.list014 = 1
								} else {
									this.list014 = 0
								}
							}

						} else {
							console.log(this.list1[2].单位内编号)
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,

								'单位内编号': this.list1[2].单位内编号,
								'气瓶数量': this.list1[2].气瓶数量,
								'安装单位': this.list1[2].安装单位,
								'安全管理员': this.list1[2].安全管理员,
								'移动电话': this.list1[2].移动电话,

								'名称': this.list1[1].名称,
								'住所': this.list1[1].住所,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[3].机动车登记编号,
								'车辆识别代号': this.list1[3].车辆识别代号,
							}
							_this.$axios.post('daochu_word_api', params, {
								responseType: 'blob',
							}).then(function(res) {
								console.log(res)
								const blob = new Blob([res.data]);
								let downloads = document.createElement("a");
								let href = window.URL.createObjectURL(blob);
								downloads.href = href;
								downloads.download = 'cccc.docx';
								document.body.appendChild(downloads);
								downloads.click();
								document.body.removeChild(downloads);
								window.URL.revokeObjectURL(href);
							})
						}


					}


				}

			},
			dis_certificate_api() {
				var _this = this
				let formData = new FormData()
				if (this.img_list1[this.current2].title == '授权委托书及代理人身份证明') {
					//this.dis_certificate_api()
				} else {
					if (this.current2 == 0) {
						console.log(123)
						console.log(this.current3)
						for (var i = 0; i <= this.img_list1[0].hege[this.current3].img.length - 1; i++) {
							var file = _this.dataURLtoFile(this.img_list1[0].hege[this.current3].img[i].imagePath, 143)
							console.log(URL.createObjectURL(file))

							formData.append('img', file)
						}
						//console.log()

					} else if (this.current2 == 1) {
						if (this.img_list1[1].title == '身份证明') {
							for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
								var file = _this.dataURLtoFile(this.img_list1[1].img[i].imagePath, 143)
								formData.append('img', file)
							}
						} else {
							var file = _this.dataURLtoFile(this.img_list1[1].img.imagePath, 143)
							formData.append('img', file)
						}
					} else if (this.current2 == 2 || this.current2 == 3 || this.current2 == 4) {
						for (var i = 0; i <= this.img_list1[this.current2].img.length - 1; i++) {
							var file = _this.dataURLtoFile(this.img_list1[this.current2].img[i].imagePath, 143)
							formData.append('img', file)
						}
					}
					if (this.img_list1[this.current2].title == '身份证明') {
						this.list[this.current2].list = ["姓名", "住址", "公民身份号码", '有效期限']
					} else if (this.img_list1[this.current2].title == '营业执照') {
						this.list[this.current2].list = ["名称", "住所", "统一社会信用代码"]
					}
					formData.append('name_list', this.list[this.current2].list)
					_this.$axios.post('dis_certificate_api', formData).then(function(res) {
						console.log(res)
						_this.saomiao_show = false
						if (res.data.data == '请重新识别') {
							if (_this.current3 >= _this.img_list1[0].hege.length - 1) {
								_this.current2 = _this.current2 + 1
							} else {
								_this.current3 = _this.current3 + 1
							}

							//console.log(_this.list1)
							_this.$forceUpdate();
							if (_this.current2 > _this.img_list1.length - 1) {
								_this.current2 = 0
							} else {
								_this.dis_certificate_api()
							}
						} else {
							if (_this.current2 == 0) {
								if (_this.current3 == 0) {
									console.log("current3-------0")
									_this.list1[_this.current2].产品名称 = res.data.产品名称
									_this.list1[_this.current2].充装介质 = res.data.充装介质
									_this.list1[_this.current2].工作压力 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期 = res.data.制造日期
									_this.list1[_this.current2].产品编号 = res.data.产品编号
								} else if (_this.current3 == 1) {
									_this.list1[_this.current2].工作压力1 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积1 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称1 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期1 = res.data.制造日期
									_this.list1[_this.current2].产品编号1 = res.data.产品编号
								} else if (_this.current3 == 2) {
									_this.list1[_this.current2].工作压力2 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积2 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称2 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期2 = res.data.制造日期
									_this.list1[_this.current2].产品编号2 = res.data.产品编号
								} else if (_this.current3 == 3) {
									_this.list1[_this.current2].工作压力3 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积3 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称3 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期3 = res.data.制造日期
									_this.list1[_this.current2].产品编号3 = res.data.产品编号
								}

							} else if (_this.current2 == 1) {
								if (_this.img_list1[_this.current2].title == '身份证明') {
									_this.list1[_this.current2].姓名 = res.data.姓名
									_this.list1[_this.current2].住址 = res.data.住址
									_this.list1[_this.current2].公民身份号码 = res.data.公民身份号码
								} else if (_this.img_list1[_this.current2].title == '营业执照') {
									_this.list1[_this.current2].名称 = res.data.名称
									_this.list1[_this.current2].住所 = res.data.住所
									_this.list1[_this.current2].统一社会信用代码 = res.data.统一社会信用代码
								}
							} else if (_this.current2 == 3) {
								_this.list1[_this.current2].机动车登记编号 = res.data.机动车登记编号
								_this.list1[_this.current2].车辆识别代号 = res.data.车辆识别代号
							} else if (_this.current2 == 2) {
								_this.list1[_this.current2].气瓶数量 = res.data.气瓶数量
								_this.list1[_this.current2].安装单位 = res.data.安装单位
								_this.list1[_this.current2].安全管理员 = res.data.安全管理员
							} else if (_this.current2 == 4) {
								_this.list1[_this.current2].监督检验机构名称 = res.data.监督检验机构名称
							}

							if (_this.current3 >= _this.img_list1[0].hege.length - 1) {
								_this.current2 = _this.current2 + 1
							} else {
								_this.current3 = _this.current3 + 1
							}
							console.log(_this.list1)

							_this.list2 = JSON.parse(JSON.stringify(_this.list1))
							console.log(_this.list2)
							//_this.$forceUpdate();
							if (_this.current2 > _this.img_list1.length - 1) {
								_this.current2 = 0
							} else {
								_this.dis_certificate_api()
							}
						}

					})
				}
			},
			dis_certificate_api1() {
				var _this = this
				// _this.loading = true
				this.saomiao_show = true
				let formData = new FormData()
				//var img = []
				console.log(this.current)
				if (this.img_list1[this.current2].title == '授权委托书及代理人身份证明') {
					//this.dis_certificate_api()
				} else {
					if (this.current == 0) {
						for (var i = 0; i <= this.img_list1[0].hege[this.command_index].img.length - 1; i++) {
							//img.push(this.img_list1[0].hege[i].imagePath)
							var file = this.img_list1[0].hege[this.command_index].img[i].imagePath
							formData.append('img', file)
						}
					} else if (this.current == 1) {
						for (var i = 0; i <= this.img_list1[1].img.length - 1; i++) {
							var file = this.img_list1[1].img[i].imagePath
							formData.append('img', file)
						}
					} else if (this.current2 == 2 || this.current2 == 3 || this.current2 == 4) {
						for (var i = 0; i <= this.img_list1[this.current2].img.length - 1; i++) {
							var file = _this.dataURLtoFile(this.img_list1[this.current2].img[i].imagePath, 143)
							formData.append('img', file)
						}
					}
					if (this.img_list1[this.current].title == '身份证明') {
						this.list[this.current].list = ["姓名", "住址", "公民身份号码", '有效期限']
					} else if (this.img_list1[this.current].title == '营业执照') {
						this.list[this.current].list = ["名称", "住所", "统一社会信用代码"]
					}
					formData.append('name_list', this.list[this.current].list)
					_this.$axios.post('dis_certificate_api', formData).then(function(res) {
						console.log(res)
						_this.saomiao_show = false
						if (res.data.data == '请重新识别') {
							// _this.$message({
							// 	message: '身份证已过期请重新上传',
							// 	type: 'error'
							// });
						} else {
							if (_this.current == 0) {
								if (_this.current3 == 0) {
									console.log("current3-------0")
									_this.list1[_this.current2].产品名称 = res.data.产品名称
									_this.list1[_this.current2].充装介质 = res.data.充装介质
									_this.list1[_this.current2].工作压力 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期 = res.data.制造日期
									_this.list1[_this.current2].产品编号 = res.data.产品编号
								} else if (_this.current3 == 1) {
									_this.list1[_this.current2].工作压力1 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积1 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称1 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期1 = res.data.制造日期
									_this.list1[_this.current2].产品编号1 = res.data.产品编号
								} else if (_this.current3 == 2) {
									_this.list1[_this.current2].工作压力2 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积2 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称2 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期2 = res.data.制造日期
									_this.list1[_this.current2].产品编号2 = res.data.产品编号
								} else if (_this.current3 == 3) {
									_this.list1[_this.current2].工作压力3 = res.data.工作压力
									_this.list1[_this.current2].气瓶容积3 = res.data.气瓶容积
									_this.list1[_this.current2].制作单位名称3 = res.data.制作单位名称
									_this.list1[_this.current2].制造日期3 = res.data.制造日期
									_this.list1[_this.current2].产品编号3 = res.data.产品编号
								}
							} else if (_this.current2 == 1) {
								if (_this.img_list1[_this.current2].title == '身份证明') {
									_this.list1[_this.current2].姓名 = res.data.姓名
									_this.list1[_this.current2].住址 = res.data.住址
									_this.list1[_this.current2].公民身份号码 = res.data.公民身份号码
								} else if (_this.img_list1[_this.current2].title == '营业执照') {
									_this.list1[_this.current2].名称 = res.data.名称
									_this.list1[_this.current2].住所 = res.data.住所
									_this.list1[_this.current2].统一社会信用代码 = res.data.统一社会信用代码
								}
							} else if (_this.current2 == 3) {
								_this.list1[_this.current2].机动车登记编号 = res.data.机动车登记编号
								_this.list1[_this.current2].车辆识别代号 = res.data.车辆识别代号
							} else if (_this.current2 == 2) {
								_this.list1[_this.current2].气瓶数量 = res.data.气瓶数量
								_this.list1[_this.current2].安装单位 = res.data.安装单位
								_this.list1[_this.current2].安全管理员 = res.data.安全管理员
							} else if (_this.current2 == 4) {
								_this.list1[_this.current2].监督检验机构名称 = res.data.监督检验机构名称
							}
							console.log(_this.list1)
							_this.list2 = JSON.parse(JSON.stringify(_this.list1))
							//_this.$forceUpdate();
						}
					})
				}



			},
			handleClose() {
				this.dialogVisible1 = false
				this.dialogVisible2 = false
				this.$refs.word.innerHTML = '';
			},
			open_camera(e) {
				if (e == 0) {
					this.camera = false
				} else {
					this.camera = true
				}
				this.dialogVisible1 = true
				setTimeout(() => {
					this.getCompetence()
				}, 500)
			},
			open_camera1(e) {
				if (e == 0) {
					this.camera = false
				} else {
					this.camera = true
				}
			},
			// 调用权限（打开摄像头功能）
			getCompetence() {
				var _this = this;
				_this.thisCancas = document.getElementById("canvasCamera");
				_this.thisContext = this.thisCancas.getContext("2d");
				_this.thisVideo = document.getElementById("videoCamera");
				_this.thisVideo.style.display = "block";
				// 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
				if (navigator.mediaDevices === undefined) {
					navigator.mediaDevices = {};
				}
				// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
				// 使用getUserMedia，因为它会覆盖现有的属性。
				// 这里，如果缺少getUserMedia属性，就添加它。
				if (navigator.mediaDevices.getUserMedia === undefined) {
					navigator.mediaDevices.getUserMedia = function(constraints) {
						// 首先获取现存的getUserMedia(如果存在)
						var getUserMedia =
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia ||
							navigator.getUserMedia;
						// 有些浏览器不支持，会返回错误信息
						// 保持接口一致
						if (!getUserMedia) {
							//不存在则报错
							return Promise.reject(
								new Error("getUserMedia is not implemented in this browser")
							);
						}
						// 否则，使用Promise将调用包装到旧的navigator.getUserMedia
						return new Promise(function(resolve, reject) {
							getUserMedia.call(navigator, constraints, resolve, reject);
						});
					};
				}
				var constraints = {
					audio: false,
					video: {
						width: this.videoWidth,
						height: this.videoHeight,
						transform: "scaleX(-1)",
					},
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(function(stream) {
						// 旧的浏览器可能没有srcObject
						if ("srcObject" in _this.thisVideo) {
							_this.thisVideo.srcObject = stream;
						} else {
							// 避免在新的浏览器中使用它，因为它正在被弃用。
							_this.thisVideo.src = window.URL.createObjectURL(stream);
						}
						_this.thisVideo.onloadedmetadata = function(e) {
							_this.thisVideo.play();
						};
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//  绘制图片（拍照功能）
			setImage() {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				if (this.camera) {
					this.img_list1[this.current].img[this.current1].imagePath = file
					this.img_list1[this.current].img[this.current1].imageUrl = URL.createObjectURL(file);
					this.img_list1[this.current].img[this.current1].srcList[0] = URL.createObjectURL(file);
				} else {
					this.img_list1[this.current].hege[this.command_index].img[this.current1].imagePath = file
					this.img_list1[this.current].hege[this.command_index].img[this.current1].imageUrl = URL
						.createObjectURL(file);
					this.img_list1[this.current].hege[this.command_index].img[this.current1].srcList[0] = URL
						.createObjectURL(file);
				}
				this.dialogVisible1 = false
				this.dis_certificate_api1()
			},
			// base64转文件，此处没用到
			dataURLtoFile(dataurl, filename) {
				// console.log(dataurl, '1111111111111111')
				// console.log(filename)
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				// 将Uint8Array转换为Blob对象
				const blob = new Blob([u8arr], {
					type: mime
				});

				// 创建File对象
				const file = new File([blob], filename, {
					type: mime
				});

				return file;
			},
			todetails(e) {
				this.current1 = e
				this.restoration()
			},
			httprequest() {},
			//获取图片路径
			beforeAvatarUpload(file) {
				console.log(file)
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				if (!isJPG && !isPNG) {
					this.$message.error('只能上传图片!');
				} else {
					if (this.camera) {
						this.img_list1[this.current].img[this.current1].imagePath = file
						this.img_list1[this.current].img[this.current1].imageUrl = URL.createObjectURL(file);
						this.img_list1[this.current].img[this.current1].srcList[0] = URL.createObjectURL(file);
					} else {
						this.img_list1[this.current].hege[this.command_index].img[this.current1].imagePath = file
						this.img_list1[this.current].hege[this.command_index].img[this.current1].imageUrl = URL
							.createObjectURL(file);
						this.img_list1[this.current].hege[this.command_index].img[this.current1].srcList[0] = URL
							.createObjectURL(file);
					}
					this.dis_certificate_api1()
					// this.img_list1[this.current].imagePath = file
					// this.img_list1[this.current].imageUrl = URL.createObjectURL(file);
					// this.img_list1[this.current].srcList[0] = URL.createObjectURL(file);
				}
			},
			// aaa() {
			// 	console.log(document.body.clientWidth)
			// 	console.log(document.body.clientHeight)
			// 	this.screenHeight = document.body.clientHeight
			// },
			select(e) {
				console.log(e)
				this.current = e
				this.current1 = 0
			},
		},

	}
</script>
<style lang="less" scoped>
	.wrap {
		width: 19.98rem;
		height: calc(100vh - 1.45rem);
		margin-top: 0.26rem;
		margin-left: 0.37rem;
		//background-color: #fff;
		border-radius: 0.1rem;
		// overflow-y: auto;
		// scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
		display: flex;
	}

	.wrap::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	// .wrap {
	// 	//background-color: #000;
	// 	width: 100vw;
	// 	height: calc(100vh - 0.71rem);
	// 	background-image: url('../assets/image/index1.png');
	// 	background-size: 100% 100%;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	overflow-y: auto;
	// }

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	.select {
		margin-right: 0.32rem;
		font-weight: 400;
		font-size: 0.18rem;
		color: #7B8391;
	}

	.select1 {
		margin-right: 0.32rem;
		font-weight: bold;
		font-size: 0.18rem;
		color: #2C60F5;
	}

	.left {
		width: 11.22rem;
		height: 6.76rem;
		background: #EBEEF2;
		position: relative;
		overflow: hidden;
		margin-left: 0.37rem;
		margin-top: 0.12rem;
	}

	.right {
		width: 5.12rem;
		height: 6.96rem;
		background: #F2F6FA;
	}

	/deep/.el-upload-dragger {
		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		//margin-top: 0.26rem;
		border: none;
	}

	.cha1 {
		border: 0.02rem solid #2C60F5;
		overflow: hidden;
	}

	/deep/.el-input__inner {
		height: 0.61rem;
		border-radius: 0.1rem;
		font-size: 0.2rem;
	}

	/deep/.el-dialog__headerbtn .el-dialog__close {
		font-size: 0.35rem;
	}

	.preview-img-div {
		position: relative;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#productDrawing {
		width: 580px;
		height: 480px;
		border: 1px solid #edf1f5;
		overflow: hidden;

		.alert {
			height: 30px;
			font-size: 12px;
			line-height: 30px;
			border-radius: 2px;
			color: #9e7700;
			text-align: center;
			background: linear-gradient(90deg, #ffffff 0%, #fff7d3 50%, #fcfcfc 100%);
		}

		.productDrawing_Img {
			width: 580px;
			height: 450px;
			overflow: hidden;
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.dunpai {
		width: 11.22rem;
		height: 6.96rem;
		margin: 0 auto;
		margin-top: 209upx;
		position: relative;
	}

	.dunpai image {
		width: 100%;
		height: 100%;
	}

	.saomiao {
		width: 100%;
		height: 0.95rem;
		background: linear-gradient(180deg, rgba(44, 96, 245, 0) 0%, #2C60F5 100%);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 10px;
		animation: move 1.5s ease-in-out infinite;
		-webkit-animation: move 1.5s ease-in-out infinite;
		opacity: 0.57;
	}

	@keyframes move {
		from {
			top: 0px
		}

		/*网格移动到显示区域的外面*/
		to {
			top: 6.96rem
		}
	}

	.wrap_left {
		width: 12.76rem;
		height: 10.1rem;
		background: #FFFFFF;
		border-radius: 0.1rem;
	}

	.wrap_right {
		width: 7.07rem;
		height: 10.1rem;
		background: #FFFFFF;
		border-radius: 0.1rem;
		margin-left: 0.15rem;
	}

	.table_left {
		width: 0.46rem;
		height: 2.71rem;
		background: #FFFFFF;
		border-right: 0.01rem solid #787B81;
		border-bottom: 0.01rem solid #787B81;
		font-size: 0.15rem;
		display: flex;
		align-items: center;
		text-align: center;
		line-height: 0.25rem;
	}

	.brightness {
		background: rgba(44, 96, 245, 0.14);
	}

	/deep/.el-input__inner {
		height: 0.26rem;
		background-color: transparent;
		border-radius: 0;
		color: #000;
		font-size: 0.15rem;
		border: none;
	}

	/deep/.el-input__icon {
		line-height: 0.29rem;
		width: 0.01rem;
		height: 0.01rem;
		display: none;
	}

	/deep/.el-input--prefix .el-input__inner {
		padding: 0;
	}

	/deep/.el-input--suffix .el-input__inner {
		padding: 0;
	}

	.select1 .el-dropdown-link {
		//cursor: pointer;
		font-size: 0.18rem;
		color: #2C60F5;
	}

	.select .el-dropdown-link {
		//cursor: pointer;
		font-size: 0.18rem;
		color: #7B8391;
	}

	.background {
		background-color: red;
	}
</style>