<template>
	<div class="wrap">
		<el-dialog title="" :visible.sync="dialogVisible" width="7.07rem" :before-close="handleClose">
			<div style="text-align: center;position: relative;">
				<img style="width: 3.74rem;height: 2.63rem;" src="../assets/image/identify2.png" alt="" srcset="" />
				<div
					style="font-weight: 400;font-size: 0.22rem;color: #7B8391;position: absolute;bottom: 0rem;left: 50%;margin-left: -2.1rem;">
					*未完成所有项图片的上传，请全部上传后再识别</div>
			</div>
			<div @click="dialogVisible = false"
				style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin: 0 auto;margin-top: 0.45rem;margin-bottom: 0.3rem;">
				返回
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible1" width="15.44rem" :before-close="handleClose">
			<div class="camera_outer"
				style="width: 14.44rem;display: flex;justify-content: center;flex-direction: column;align-items: center;margin: 0 auto;height: 8.9rem;">
				<video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
				<canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
				<div v-if="!camera">
					<div @click="setImage(0)" v-if="!camera_show"
						style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
						点击拍照</div>
					<div style="display: flex;" v-if="camera_show">
						<div @click="setImage(1)"
							style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;margin-right: 0.3rem;">
							重新拍照</div>
						<div @click="setImage(0)"
							style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
							继续拍照</div>
					</div>
				</div>

				<div v-if="camera" @click="setImage1()"
					style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
					点击拍照</div>
			</div>
		</el-dialog>
		<div style="width: 19.66rem;margin: 0 auto;min-width: 19.66rem;">
			<div style="display: flex;justify-content: space-between;">
				<div>
					<div style="display: flex;align-items: center;margin-top: 0.2rem;">
						<img style="width: 0.27rem;height: 0.27rem;margin-right: 0.12rem;"
							src="../assets/image/index2.png" alt="" srcset="" />
						<div class="my_template">特种设备使用登记</div>
					</div>
					<div style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-top: 0.06rem;">
						新设备首次启用，适用于车用气瓶使用登记</div>
				</div>
				<div style="display: flex;margin-top: 0.27rem;">
					<div @click="identify"
						style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
						生成登记表</div>
				</div>
			</div>
		</div>
		<div style="width: 19.66rem;height: 8.5rem;margin: 0 auto;margin-top: 0.46rem;">
			<div
				style="width: 19.66rem;height: 0.5rem;border: 0.01rem solid #E2E9F9;background-color: #EBF1FF;display: flex;font-size: 0.2rem;color: #7B8391;">
				<div class="title" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					序号
				</div>
				<div class="title" style="width: 7.26rem;border-right: 1px solid #E2E9F9;padding-left: 0.43rem;">
					名称
				</div>
				<div class="title" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					资料
				</div>
				<div class="title" style="width: 4.09rem;padding-left: 0.43rem;">
					拍照/上传操作
				</div>
			</div>
			<!-- *特种设备产品合格证 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/identify6.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style=""><span style="color: #DF2525;">*</span>特种设备产品合格证</div>
							<div>
								<img @click="add" style="width: 0.3rem;height: 0.3rem;margin-left: 0.2rem;"
									src="../assets/image/identify3.png" alt="" />
								<img @click="del" style="width: 0.3rem;height: 0.3rem;margin-left: 0.1rem;"
									src="../assets/image/identify11.png" alt="" />
							</div>
						</div>
						<div style="display: flex;margin-top: 0.15rem;">
							<div @click="switch1(index)" v-for="(item,index) in hege"
								:class="hege_index == index?'switch':'switch1'"
								style="width: 1.18rem;height: 0.45rem;text-align: center;line-height: 0.45rem;">
								第{{index+1}}组
							</div>
							<!-- <div
								style="width: 1.18rem;height: 0.45rem;background: #DEE6FF;text-align: center;line-height: 0.45rem;color: #2C60F5;">
								2
							</div> -->
						</div>
					</div>
				</div>

				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;width: 6.54rem;">
						<div v-if="hege[hege_index].img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in hege[hege_index].img">
							<!-- <div @click.stop="image_close(index,0)"
								style="position: absolute;bottom: 0.09rem;right: 0.07rem;z-index: 999;width: 0.79rem;height: 0.41rem;background: #DF2525;text-align: center;line-height: 0.41rem;color: #fff;font-size: 0.2rem;">
								删除
							</div> -->
							<img @click.stop="image_close(index,0)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="hege[hege_index].img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(0)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(0)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- *含有使用单位统一社会信用代码的证明或者个人身份证明 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/7.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style=""><span style="color: #DF2525;">*</span>含有使用单位统一社会信用代码的证明或者个人身份证明</div>
						</div>
						<div style="display: flex;margin-top: 0.15rem;">
							<div @click="card(index)" v-for="(item,index) in id_list"
								:class="current == index?'switch':'switch1'"
								style="width: 1.18rem;height: 0.45rem;text-align: center;line-height: 0.45rem;">
								{{item}}
							</div>
							<!-- <div 
								style="width: 1.18rem;height: 0.45rem;background: #2C60F5;text-align: center;line-height: 0.45rem;color: #fff;">
								身份证
							</div>
							<div
								style="width: 1.18rem;height: 0.45rem;background: #DEE6FF;text-align: center;line-height: 0.45rem;color: #2C60F5;">
								营业执照
							</div> -->
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<!-- 身份证 -->
					<div v-if="current == 0" style="display: flex;width: 6.54rem;">
						<div v-if="idCard.img.length != 0" style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in idCard.img">
							<img @click.stop="image_close(index,1)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<!-- idCard: {
							title: '身份证明',
							img: []
						},
						businesslicense: {
							title: '营业执照',
							img: []
						}, -->
						<div v-if="idCard.img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
					<!-- 营业执照 -->
					<div v-if="current == 1" style="display: flex;width: 6.54rem;">
						<div v-if="businesslicense.img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in businesslicense.img">
							<img @click.stop="image_close(index,1)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="businesslicense.img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<!-- <div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;justify-content: space-between;width: 6.54rem;">
						<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
					</div>
				</div> -->
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(1)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(1)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- *安装合格证 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/identify7.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style=""><span style="color: #DF2525;">*</span>安装合格证</div>
						</div>
						<div style="display: flex;margin-top: 0.1rem;color: #95A0B2;font-size: 0.18rem;">
							(含气瓶数量、施工单位名称以及安全管理员)
						</div>
					</div>
				</div>
				<!-- InstallationCertificate -->
				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;width: 6.54rem;">
						<div v-if="InstallationCertificate.img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in InstallationCertificate.img">
							<img @click.stop="image_close(index,2)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="InstallationCertificate.img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(2)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(2)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- *机动车登记证书 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/identify8.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style=""><span style="color: #DF2525;">*</span>机动车登记证书</div>
						</div>
						<div style="display: flex;margin-top: 0.1rem;color: #95A0B2;font-size: 0.18rem;">
							(适用于与机动车固定的车用气瓶)
						</div>
					</div>
				</div>
				<!-- CarRegistration -->
				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;width: 6.54rem;">
						<div v-if="CarRegistration.img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in CarRegistration.img">
							<img @click.stop="image_close(index,3)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="CarRegistration.img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(3)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(3)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 特种设备监督检验证明 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/identify9.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style="">特种设备监督检验证明</div>
						</div>
						<div style="display: flex;margin-top: 0.1rem;color: #95A0B2;font-size: 0.17rem;">
							(安全技术规范要求进行使用前首次检验的特种设备，应当提交使用前的首次检验报告）
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;width: 6.54rem;">
						<div v-if="SupervisionInspectionCertificate.img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in SupervisionInspectionCertificate.img">
							<img @click.stop="image_close(index,4)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="SupervisionInspectionCertificate.img.length == 0"
							style="width: 1.3rem;text-align: center;" v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(4)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(4)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 授权委托书及代理人身份证明 -->
			<div
				style="width: 19.66rem;height: 1.35rem;border-left: 0.01rem solid #E2E9F9;border-right: 0.01rem solid #E2E9F9;border-bottom: 0.01rem solid #E2E9F9;display: flex;font-size: 0.2rem;color: #7B8391;align-items: center;">
				<div class="title_list" style="width: 0.95rem;padding-left: 0.29rem;border-right: 1px solid #E2E9F9;">
					<img src="../assets/image/identify10.png" alt="" />
				</div>
				<div class="title_list" style="width: 7.26rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div>
						<div style="display: flex;align-items: center;">
							<div style="">授权委托书及代理人身份证明</div>
						</div>
						<div style="display: flex;margin-top: 0.1rem;color: #95A0B2;font-size: 0.18rem;">
							(申请人委托他人办理时需提交)
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 7.4rem;padding-left: 0.43rem;border-right: 1px solid #E2E9F9;">
					<div style="display: flex;width: 6.54rem;">
						<div v-if="powerOfAttorney.img.length != 0"
							style="width: 1.3rem;text-align: center;position: relative;"
							v-for="(item,index) in powerOfAttorney.img">
							<img @click.stop="image_close(index,5)"
								style="width: 0.28rem;height: 0.28rem;position: absolute;top: -0.04rem;right: -0.04rem;z-index: 999;"
								src="../assets/image/image_close.png" alt="" />
							<el-image style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl"
								:preview-src-list="item.srcList" :initial-index='index'>
							</el-image>
							<!-- <img style="width: 1.11rem;height: 1.11rem;" :src="item.imageUrl" alt="" /> -->
						</div>
						<div v-if="powerOfAttorney.img.length == 0" style="width: 1.3rem;text-align: center;"
							v-for="(item,index) in 5">
							<img style="width: 1.11rem;height: 1.11rem;" src="../assets/image/identify12.png" alt="" />
						</div>
					</div>
				</div>
				<div class="title_list" style="width: 4.09rem;padding-left: 0.34rem;">
					<div @click="open_camera(5)"
						style="width: 1.63rem;height: 0.55rem;background: #2C60F5;display: flex;align-items: center;justify-content: center;">
						<img src="../assets/image/identify4.png" alt="" />
						<div style="color: #fff;margin-left: 0.05rem;">点击拍照</div>
					</div>
					<div
						style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;margin-left: 0.2rem;">
						<div class="file-upload-wrapper">
							<input type="file" multiple ref="fileupload" id="file-upload" @change="handleFileChange"
								style="display: none;">
							<div @click="triggerFileUpload(5)"
								style="width: 1.63rem;height: 0.55rem;background: #F6990E;display: flex;align-items: center;justify-content: center;">
								<img src="../assets/image/identify5.png" alt="" />
								<div style="color: #fff;margin-left: 0.05rem;">上传图片</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- <div style="margin-top: 0.25rem;">
			
		</div> -->
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	export default {
		data() {
			return {
				current: 0,
				select_index: 0,
				hege_index: 0,
				select_list: '',
				hege: [{
					title: '特种设备产品合格证',
					img: []
				}],
				id_list: ['身份证', '营业执照'],
				idCard: {
					title: '身份证明',
					img: []
				},
				businesslicense: {
					title: '营业执照',
					img: []
				},
				InstallationCertificate: {
					title: '安装合格证',
					img: []
				},
				CarRegistration: {
					title: '机动车登记证书',
					img: []
				},
				SupervisionInspectionCertificate: {
					title: '特种设备监督检验证明',
					img: []
				},
				powerOfAttorney: {
					title: '授权委托书及代理人身份证明',
					img: []
				},
				// list: [{
				// 	list: [{
				// 		img: [],
				// 		name: {
				// 			'车用气瓶': '车用气瓶',
				// 			'产品名称': '',
				// 			'充装介质': '',
				// 			'工作压力': '',
				// 			'气瓶容积': '',
				// 			'制作单位名称': '',
				// 			'制造日期': '',
				// 			'产品编号': '',
				// 		}
				// 	}]
				// }, {
				// 	img: [],
				// 	name: {
				// 		'车用气瓶': '车用气瓶',
				// 		'产品名称': '',
				// 		'充装介质': '',
				// 		'工作压力': '',
				// 		'气瓶容积': '',
				// 		'制作单位名称': '',
				// 		'制造日期': '',
				// 		'产品编号': '',
				// 	}
				// }],
				// idCard: [{
				// 	title: '身份证明',
				// 	imageUrl: '',
				// 	imagePath: '',
				// 	value: false,
				// 	srcList: [],
				// }, {
				// 	title: '身份证明',
				// 	imageUrl: '',
				// 	imagePath: '',
				// 	value: false,
				// 	srcList: [],
				// }],
				// businesslicense: {
				// 	title: '营业执照',
				// 	imageUrl: '',
				// 	imagePath: '',
				// 	value: false,
				// 	srcList: [],
				// },
				selectedFiles: [],
				base64String: null,
				camera_show: false,
				show: false,
				videoWidth: 960,
				videoHeight: 600,
				imgSrc: "",
				thisCancas: null,
				thisContext: null,
				thisVideo: null,
				openVideo: false,
				dialogVisible1: false,
				camera: false,
				show1: true,
				time1: '',

				imageUrl: '',
				imagePath: '',
				srcList: [],
				srcList1: [],
				value: false,
				index: 0,
				dialogVisible: false,
				img_list: [{
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}],
				img_list1: []
			}
		},
		created() {

		},
		watch: {

		},
		mounted() {
			//this.getCompetence(); //进入页面就调用摄像头
			this.time()

		},
		computed: {

		},
		methods: {
			del() {
				console.log(this.hege_index)
				this.hege.splice(1, 1)
				this.hege.splice(this.hege_index, 1)
				this.hege_index = 0
			},
			switch1(e) {
				this.hege_index = e
			},
			card(e) {
				this.current = e
			},
			add() {
				var list = {
					title: '特种设备产品合格证（多张）',
					img: []
				}
				this.hege.push(list)
			},
			triggerFileUpload(e) {
				console.log(e)
				this.select_list = e
				document.getElementById('file-upload').click()
				document.getElementById('file-upload').value = ""
				//this.$refs.fileupload.click();
			},
			createArrayWithLength(length) {
				// 使用fill和Array.from创建特定长度的数组
				return Array.from({
					length: length
				}).fill(null);
			},
			handleFileChange(event) {
				var _this = this
				console.log(event.target.files);
				var file = event.target.files
				var array = this.createArrayWithLength(file.length)
				array.map((item, index) => {
					this.getBase64(file[index]).then(res => {
						console.log(res)
						var list = {
							imageUrl: URL.createObjectURL(file[index]),
							imagePath: res,
							value: true,
							srcList: [URL.createObjectURL(file[index])],
						}
						if (_this.select_list == 0) {
							_this.hege[_this.hege_index].img.push(list)
						} else if (_this.select_list == 1) {
							console.log(123)
							if (_this.current == 0) {
								_this.idCard.img.push(list)
							} else if (_this.current == 1) {
								_this.businesslicense.img.push(list)
							}
						} else if (_this.select_list == 2) {
							_this.InstallationCertificate.img.push(list)
						} else if (_this.select_list == 3) {
							_this.CarRegistration.img.push(list)
						} else if (_this.select_list == 4) {
							_this.SupervisionInspectionCertificate.img.push(list)
						} else if (_this.select_list == 5) {
							_this.powerOfAttorney.img.push(list)
						}

					})
				})
				// 处理文件
				// var file = event.target.files
				// var array = this.createArrayWithLength(file.length)
				// array.map((item, index) => {
				// 	this.getBase64(file[index]).then(res => {
				// 		console.log(res)
				// 		//this.img_list1[this.index].hege[this.index].imagePath = res
				// 		console.log(this.img_list1[0].hege.length - 1)
				// 		this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath = res
				// 		console.log(this.img_list1[0].hege.length - 1)
				// 		console.log(this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath)
				// 		this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl = URL
				// 			.createObjectURL(file[
				// 				index]);
				// 		this.img_list1[0].hege[this.img_list1[0].hege.length - 1].srcList[0] = URL
				// 			.createObjectURL(
				// 				file[index]);
				// 		this.srcList.push(URL.createObjectURL(file[index]))
				// 		if (this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl != '') {
				// 			this.img_list1[0].hege[this.img_list1[0].hege.length - 1].value = true
				// 		}
				// 		//setTimeout(() => {
				// 		var list = {
				// 			imageUrl: '',
				// 			imagePath: '',
				// 			value: false,
				// 			srcList: [],
				// 		}
				// 		this.img_list1[0].hege.push(list)
				// 		//}, 500)
				// 	})
				// })
			},
			time() {
				let currentDate = new Date();
				let year = currentDate.getFullYear();
				let month = currentDate.getMonth() + 1; // 注意月份从0开始，需要加1
				let day = currentDate.getDate();
				// 输出当前日期
				console.log(year + "-" + month + "-" + day);
				this.time1 = year + "-" + month + "-" + day
			},
			handleClose() {
				this.dialogVisible = false
				this.dialogVisible1 = false
			},
			// 调用权限（打开摄像头功能）
			getCompetence() {
				var _this = this;
				_this.thisCancas = document.getElementById("canvasCamera");
				_this.thisContext = this.thisCancas.getContext("2d");
				_this.thisVideo = document.getElementById("videoCamera");
				_this.thisVideo.style.display = "block";
				// 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
				if (navigator.mediaDevices === undefined) {
					navigator.mediaDevices = {};
				}
				// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
				// 使用getUserMedia，因为它会覆盖现有的属性。
				// 这里，如果缺少getUserMedia属性，就添加它。
				if (navigator.mediaDevices.getUserMedia === undefined) {
					navigator.mediaDevices.getUserMedia = function(constraints) {
						// 首先获取现存的getUserMedia(如果存在)
						var getUserMedia =
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia ||
							navigator.getUserMedia;
						// 有些浏览器不支持，会返回错误信息
						// 保持接口一致
						if (!getUserMedia) {
							//不存在则报错
							return Promise.reject(
								new Error("getUserMedia is not implemented in this browser")
							);
						}
						// 否则，使用Promise将调用包装到旧的navigator.getUserMedia
						return new Promise(function(resolve, reject) {
							getUserMedia.call(navigator, constraints, resolve, reject);
						});
					};
				}
				var constraints = {
					audio: false,
					video: {
						width: this.videoWidth,
						height: this.videoHeight,
						transform: "scaleX(-1)",
					},
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(function(stream) {
						// 旧的浏览器可能没有srcObject
						if ("srcObject" in _this.thisVideo) {
							_this.thisVideo.srcObject = stream;
						} else {
							// 避免在新的浏览器中使用它，因为它正在被弃用。
							_this.thisVideo.src = window.URL.createObjectURL(stream);
						}
						_this.thisVideo.onloadedmetadata = function(e) {
							_this.thisVideo.play();
							_this.show1 = false
						};
					})
					.catch((err) => {
						console.log(err);
					});
			},
			open_camera(id) {
				var _this = this
				//console.log(e)
				this.select_index = id
				this.dialogVisible1 = true
				this.camera = false
				this.camera_show = false
				setTimeout(() => {
					_this.getCompetence()
				}, 500)
			},
			//  绘制图片（拍照功能）
			setImage(e) {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				var list = {
					imageUrl: URL.createObjectURL(file),
					imagePath: image,
					value: true,
					srcList: [URL.createObjectURL(file)],
				}
				if (this.select_index == 0) {
					if (e == 0) {
						this.hege[this.hege_index].img.push(list)
					} else if (e == 1) {
						this.hege[this.hege_index].img[this.hege[this.hege_index].img.length - 1] = list
					}
				} else if (this.select_index == 1) {
					if (e == 0) {
						if (this.current == 0) {
							this.idCard.img.push(list)
						} else if (this.current == 1) {
							this.businesslicense.img.push(list)
						}

					} else if (e == 1) {
						if (this.current == 0) {
							this.idCard.img[this.idCard.img.length - 1] = list
						} else if (this.current == 1) {
							this.businesslicense.img[this.businesslicense.img.length - 1] = list
						}
					}
				} else if (this.select_index == 2) {
					if (e == 0) {
						this.InstallationCertificate.img.push(list)
					} else if (e == 1) {
						this.InstallationCertificate.img[this.InstallationCertificate.img.length - 1] = list
					}
				} else if (this.select_index == 3) {
					if (e == 0) {
						this.CarRegistration.img.push(list)
					} else if (e == 1) {
						this.CarRegistration.img[this.CarRegistration.img.length - 1] = list
					}
				} else if (this.select_index == 4) {
					if (e == 0) {
						this.SupervisionInspectionCertificate.img.push(list)
					} else if (e == 1) {
						this.SupervisionInspectionCertificate.img[this.SupervisionInspectionCertificate.img.length - 1] =
							list
					}
				} else if (this.select_index == 5) {
					if (e == 0) {
						this.powerOfAttorney.img.push(list)
					} else if (e == 1) {
						this.powerOfAttorney.img[this.powerOfAttorney.img.length - 1] = list
					}
				}
				console.log(this.hege)
				this.camera_show = true
			},

			setImage2() {
				this.index += 1
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				//console.log(file)
				//_this.imgSrc = image; //赋值并预览图片
				this.img_list1[0].hege[this.index].imagePath = image
				//console.log(this.img_list[this.index].imagePath)
				//this.imageUrl = data.file
				this.img_list1[0].hege[this.index].imageUrl = URL.createObjectURL(file);
				this.img_list1[0].hege[this.index].srcList[0] = URL.createObjectURL(file);
				this.srcList.push(URL.createObjectURL(file))
				if (this.img_list1[0].hege[this.index].imageUrl != '') {
					this.img_list1[0].hege[this.index].value = true
				}

				var list = {
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}
				if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[this.index].imageUrl !=
					'') {
					this.img_list1[0].hege.push(list)
				}

				//this.dialogVisible1 = false
			},

			setImage1() {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				this.img_list1[this.index].imagePath = image
				//console.log(this.img_list1[this.index].imagePath)
				//this.imageUrl = data.file
				this.img_list1[this.index].imageUrl = URL.createObjectURL(file);
				this.img_list1[this.index].srcList[0] = URL.createObjectURL(file);
				this.srcList1.push(URL.createObjectURL(file))
				if (this.img_list1[this.index].imageUrl != '') {
					this.img_list1[this.index].value = true
				}

				this.dialogVisible1 = false

				if (this.img_list1[this.index].title == '身份证明') {
					this.idCard[0].imageUrl = URL.createObjectURL(file);
					this.idCard[0].imagePath = image;
					this.idCard[0].value = true
					this.idCard[0].srcList = URL.createObjectURL(file);
				} else if (this.img_list1[this.index].title == '') {
					this.idCard[1].imageUrl = URL.createObjectURL(file);
					this.idCard[1].imagePath = image
					this.idCard[1].value = true
					this.idCard[1].srcList = URL.createObjectURL(file);
				} else if (this.img_list1[this.index].title == '营业执照') {
					this.businesslicense.imageUrl = URL.createObjectURL(file);
					this.businesslicense.imagePath = image
					this.businesslicense.value = true
					this.businesslicense.srcList = URL.createObjectURL(file);
				}
			},
			// 关闭摄像头
			stopNavigator() {
				this.thisVideo.srcObject.getTracks()[0].stop();
			},
			// base64转文件，此处没用到
			dataURLtoFile(dataurl, filename) {
				// var arr = dataurl.split(",");
				// var mime = arr[0].match(/:(.*?);/)[1];
				// var bstr = atob(arr[1]);
				// var n = bstr.length;
				// var u8arr = new Uint8Array(n);
				// while (n--) {
				// 	u8arr[n] = bstr.charCodeAt(n);
				// }
				// return new File([u8arr], filename, {
				// 	type: mime
				// });
				// 将base64的数据部分提取出来
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				// 将Uint8Array转换为Blob对象
				const blob = new Blob([u8arr], {
					type: mime
				});

				// 创建File对象
				const file = new File([blob], filename, {
					type: mime
				});

				return file;
			},

			identify() {
				console.log(123)
				var _this = this
				if (this.hege[0].img.length == 0 || this.InstallationCertificate.img.length == 0 || this.CarRegistration
					.img.length == 0) {
					this.dialogVisible = true
				} else if (this.current == 0 && this.idCard.img.length == 0) {
					this.dialogVisible = true
				} else if (this.current == 1 && this.businesslicense.img.length == 0) {
					this.dialogVisible = true
				} else {
					// this.img_list1[0].hege.splice(this.img_list1[0].hege.length - 1, 1)
					if (this.current == 0) {
						var list1 = [{
							'车用气瓶': '车用气瓶',
							'产品名称': '',
							'充装介质': '',
							'工作压力': '',
							'气瓶容积': '',
							'制作单位名称': '',
							'制造日期': '',
							'产品编号': '',
							
							'工作压力1': '',
							'气瓶容积1': '',
							'制作单位名称1': '',
							'制造日期1': '',
							'产品编号1': '',
							
							'工作压力2': '',
							'气瓶容积2': '',
							'制作单位名称2': '',
							'制造日期2': '',
							'产品编号2': '',
							
							'工作压力3': '',
							'气瓶容积3': '',
							'制作单位名称3': '',
							'制造日期3': '',
							'产品编号3': '',
						}, {
							'姓名': '',
							'住址': '',
							'公民身份号码': '',
							'邮政编码': '052160',
							'有效期限': '',
						}, {
							'单位内编号': '',
							'单位内编号1': '',
							'单位内编号2': '',
							'单位内编号3': '',
							'气瓶数量': '',
							'安装单位': '',
							'安全管理员': '',
							'投入使用日期': this.time1,
							'移动电话': '',
						}, {
							'车牌号': '',
							'车辆识别代号': '',
						}]
					} else if (this.current == 1) {
						var list1 = [{
							'车用气瓶': '车用气瓶',
							'产品名称': '',
							'充装介质': '',
							'工作压力': '',
							'气瓶容积': '',
							'制作单位名称': '',
							'制造日期': '',
							'产品编号': '',
							
							'工作压力1': '',
							'气瓶容积1': '',
							'制作单位名称1': '',
							'制造日期1': '',
							'产品编号1': '',
							
							'工作压力2': '',
							'气瓶容积2': '',
							'制作单位名称2': '',
							'制造日期2': '',
							'产品编号2': '',
							
							'工作压力3': '',
							'气瓶容积3': '',
							'制作单位名称3': '',
							'制造日期3': '',
							'产品编号3': '',
						}, {
							'名称': '',
							'住所': '',
							'统一社会信用代码': '',
							'邮政编码': '052160',
						}, {
							'车牌号': '',
							'车辆识别代号': '',
						}, {
							'单位内编号': '',
							'单位内编号1': '',
							'单位内编号2': '',
							'单位内编号3': '',
							'气瓶数量': '',
							'安装单位': '',
							'安全管理员': '',
							'投入使用日期': this.time1,
							'移动电话': '',
						}]
					}
					var push1 = {
						title: '特种设备产品合格证（多张）',
						hege: this.hege
					}
					if (this.current == 0) {
						var push2 = this.idCard
					} else if (this.current == 1) {
						var push2 = this.businesslicense
					}

					var push3 = this.InstallationCertificate
					var push4 = this.CarRegistration
					this.img_list1.push(push1)
					this.img_list1.push(push2)
					this.img_list1.push(push3)
					this.img_list1.push(push4)
					
					console.log(this.img_list1)
					if (this.SupervisionInspectionCertificate.img.length != 0) {
						var push5 = this.SupervisionInspectionCertificate
						this.img_list1.push(push5)
					}
					if (this.powerOfAttorney.img.length != 0) {
						var push6 = this.powerOfAttorney
						this.img_list1.push(push6)
					}
					if (this.SupervisionInspectionCertificate.img.length != 0) {
						var list2 = {
							'监督检验机构名称': ''
						}
						list1.push(list2)
					}
					setTimeout(()=>{
						_this.$router.push({
							path: "/identify_details",
							query: {
								list1: list1,
								img_list1: JSON.stringify(this.img_list1),
								name: '特种设备使用登记'
							}
						})
					},500)
					
				}
			},
			select(e, index) {
				if (e == 0) {
					this.current = 0
					this.img_list1[index].title = "身份证明"
					this.img_list1[index].imageUrl = this.idCard[0].imageUrl
					this.img_list1[index].imagePath = this.idCard[0].imagePath
					this.img_list1[index].value = this.idCard[0].value
					this.img_list1[index].srcList = this.idCard[0].srcList
					var list = {
						title: '',
						imageUrl: this.idCard[1].imageUrl,
						imagePath: this.idCard[1].imagePath,
						value: this.idCard[1].value,
						srcList: this.idCard[1].srcList,
					}
					if (this.img_list1[index + 1].title != '') {
						this.img_list1.splice(index + 1, 0, list)
					}

				} else if (e == 1) {
					this.current = 1
					this.img_list1[index].title = "营业执照"
					this.img_list1[index].imageUrl = this.businesslicense.imageUrl
					this.img_list1[index].imagePath = this.businesslicense.imagePath
					this.img_list1[index].value = this.businesslicense.value
					this.img_list1[index].srcList = this.businesslicense.srcList

					if (this.img_list1[index + 1].title == '') {
						this.img_list1.splice(index + 1, 1)
					}



				}
			},
			updata(e) {
				this.index = e
				this.img_list1[0].hege[e].value = false //开启图片上传
				setTimeout(() => {
					this.img_list1[0].hege[e].value = true //开启图片上传
				}, 300)

			},
			updata1(e) {
				console.log(13)
				this.index = e
				this.img_list1[e].value = false //开启图片上传
				setTimeout(() => {
					this.img_list1[e].value = true //开启图片上传
				}, 300)

			},
			getindex(e) {
				this.index = e
			},
			httprequest() {},
			//获取图片路径
			convertToBase64(file) {
				if (file) {
					const reader = new FileReader();
					reader.onload = (e) => {
						this.base64String = e.target.result;
					};
					reader.readAsDataURL(file);
				}
			},
			getBase64(file) {
				console.log(111)
				return new Promise((resolve, reject) => {
					let reader = new FileReader()
					reader.readAsDataURL(file)
					reader.onload = function() {
						resolve(reader.result)
					}
					reader.onerror = function(error) {
						reject(error)
					}
				})
			},
			beforeAvatarUpload(file) {
				setTimeout(() => {
					console.log(file)
					const isJPG = file.type === 'image/jpeg';
					const isPNG = file.type === 'image/png';
					if (!isJPG && !isPNG) {
						this.$message.error('只能上传图片!');
					} else {
						this.getBase64(file).then(res => {
							console.log(res)

							//this.img_list1[this.index].hege[this.index].imagePath = res
							this.img_list1[0].hege[this.index].imagePath = res
							this.img_list1[0].hege[this.index].imageUrl = URL.createObjectURL(file);
							this.img_list1[0].hege[this.index].srcList[0] = URL.createObjectURL(file);
							this.srcList.push(URL.createObjectURL(file))
							if (this.img_list1[0].hege[this.index].imageUrl != '') {
								this.img_list1[0].hege[this.index].value = true
							}
							var list = {
								imageUrl: '',
								imagePath: '',
								value: false,
								srcList: [],
							}
							if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[
									this.index]
								.imageUrl !=
								'') {
								this.img_list1[0].hege.push(list)
							}
						})


					}
				}, 1000)

			},
			beforeAvatarUpload1(file) {
				console.log(file)
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				if (!isJPG && !isPNG) {
					this.$message.error('只能上传图片!');
				} else {
					this.getBase64(file).then(res => {
						this.img_list1[this.index].imagePath = res
						//console.log(this.img_list1[this.index].imagePath)
						//this.imageUrl = data.file
						this.img_list1[this.index].imageUrl = URL.createObjectURL(file);
						this.img_list1[this.index].srcList[0] = URL.createObjectURL(file);
						this.srcList1.push(URL.createObjectURL(file))
						if (this.img_list1[this.index].imageUrl != '') {
							this.img_list1[this.index].value = true
						}

						if (this.img_list1[this.index].title == '身份证明') {
							this.idCard[0].imageUrl = res
							this.idCard[0].imagePath = URL.createObjectURL(file);
							this.idCard[0].value = true
							this.idCard[0].srcList = URL.createObjectURL(file);
						} else if (this.img_list1[this.index].title == '') {
							this.idCard[1].imageUrl = res
							this.idCard[1].imagePath = URL.createObjectURL(file);
							this.idCard[1].value = true
							this.idCard[1].srcList = URL.createObjectURL(file);
						} else if (this.img_list1[this.index].title == '营业执照') {
							this.businesslicense.imageUrl = res
							this.businesslicense.imagePath = URL.createObjectURL(file);
							this.businesslicense.value = true
							this.businesslicense.srcList = URL.createObjectURL(file);
						}
					})
				}
			},
			image_close(index, e) {
				// this.img_list1[0].hege.splice(e, 1)
				// this.srcList.splice(e, 1)
				if (e == 0) {
					this.hege[this.hege_index].img.splice(index, 1)
				} else if (e == 1) {
					if (this.current == 0) {
						this.idCard.img.splice(index, 1)
					} else if (this.current == 1) {
						this.businesslicense.img.splice(index, 1)
					}
				} else if (e == 2) {
					this.InstallationCertificate.img.splice(index, 1)
				} else if (e == 3) {
					this.CarRegistration.img.splice(index, 1)
				} else if (e == 4) {
					this.SupervisionInspectionCertificate.img.splice(index, 1)
				} else if (e == 5) {
					this.powerOfAttorney.img.splice(index, 1)
				}
			},
			image_close1(e) {
				this.img_list1[e].imageUrl = ''
				this.img_list1[e].imagePath = ''
				this.img_list1[e].value = false
				this.img_list1[e].srcList = []

				// if (this.img_list1[this.index].title == '身份证明') {
				// 	this.idCard[0].imageUrl = URL.createObjectURL(file);
				// 	this.idCard[0].imagePath = image;
				// 	this.idCard[0].value = true
				// 	this.idCard[0].srcList = URL.createObjectURL(file);
				// } else if (this.img_list1[this.index].title == '') {
				// 	this.idCard[1].imageUrl = URL.createObjectURL(file);
				// 	this.idCard[1].imagePath = image
				// 	this.idCard[1].value = true
				// 	this.idCard[1].srcList = URL.createObjectURL(file);
				// } else if (this.img_list1[this.index].title == '营业执照') {
				// 	this.businesslicense.imageUrl = URL.createObjectURL(file);
				// 	this.businesslicense.imagePath = image
				// 	this.businesslicense.value = true
				// 	this.businesslicense.srcList = URL.createObjectURL(file);
				// }
				if (this.img_list1[e].title == '身份证明') {
					this.idCard[0].imageUrl = ''
					this.idCard[0].imagePath = ''
					this.idCard[0].value = false
					this.idCard[0].srcList = []
				} else if (this.img_list1[e].title == '') {
					this.idCard[1].imageUrl = ''
					this.idCard[1].imagePath = ''
					this.idCard[1].value = false
					this.idCard[1].srcList = []
				} else if (this.img_list1[e].title == '营业执照') {
					this.businesslicense.imageUrl = ''
					this.businesslicense.imagePath = ''
					this.businesslicense.value = false
					this.businesslicense.srcList = []
				}
				this.srcList1.splice(e, 1)
			},
			open(e) {
				if (this.img_list1[0].hege[e].imageUrl == '') {
					this.img_list1[0].hege[e].value = false //开启图片上传
				}
			},
			open1(e) {
				if (this.img_list1[e].imageUrl == '') {
					this.img_list1[e].value = false //开启图片上传
				}
			},
		},

	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 20.48rem;
		height: calc(100vh - 1.25rem);
		margin-top: 0.27rem;
		margin-left: 0.37rem;
		background-color: #fff;
		border-radius: 0.1rem;
		overflow-y: auto;
		scrollbar-width: none;
		/* 对于 Firefox 隐藏滚动条 */
	}

	.wrap::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	// .wrap {
	// 	//background-color: #000;
	// 	width: 100vw;
	// 	height: calc(100vh - 0.71rem);
	// 	background-image: url('../assets/image/index1.png');
	// 	background-size: 100% 100%;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	overflow-y: auto;
	// }
	.title {
		height: 0.5rem;
		line-height: 0.5rem;
		box-sizing: border-box;
	}

	.title_list {
		height: 1.35rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 0.2rem;
		color: #1B2337;
	}

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	/deep/.el-upload-dragger {
		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		//margin-top: 0.29rem;
		border: none;
	}

	.avatar-uploader {
		width: 3.73rem;
		height: 2.31rem;
		margin-top: 0.29rem;
		text-align: center;
		border: 2px dashed #7B8391;
		position: relative;
	}

	.avatar-uploader1 {
		width: 3.73rem;
		height: 2.31rem;
		margin-top: 0.29rem;
		text-align: center;
		//border: 2px dashed #7B8391;
		position: relative;
		background-color: #EBEEF2;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 3.73rem;
		height: 2.31rem;
		line-height: 2.31rem;
		text-align: center;
	}


	// /deep/.el-upload-dragger {
	// 	background-color: transparent;
	// 	border: 2px dashed #7B8391;
	// }

	.select {
		width: 1.15rem;
		height: 0.44rem;
		background: #DEE6FF;
		font-weight: 400;
		font-size: 0.2rem;
		color: #2C60F5;
		text-align: center;
		line-height: 0.44rem;
	}

	.select1 {
		width: 1.15rem;
		height: 0.44rem;
		background: #2C60F5;
		font-weight: 400;
		font-size: 0.2rem;
		color: #FFFFFF;
		text-align: center;
		line-height: 0.44rem;
	}

	/deep/.el-dialog__headerbtn .el-dialog__close {
		font-size: 0.35rem;
	}

	.file-upload-wrapper {
		/* 添加你的自定义样式 */
		// padding: 10px;
		//border: 1px solid #ccc;
		border-radius: 5px;
		display: inline-block;
	}

	.file-upload-wrapper button {
		// padding: 5px 10px;
		// background-color: #007bff;
		// color: white;
		border: none;
		// border-radius: 5px;
		// cursor: pointer;

		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		color: #fff;
		font-size: 0.2rem;
		border-radius: 0;
	}

	.file-upload-wrapper button:hover {
		//background-color: #0056b3;
	}

	.switch {
		background: #2C60F5;
		color: #fff;
	}

	.switch1 {
		background: #DEE6FF;
		color: #2C60F5;
	}
</style>